import {
  getAudioStream,
  getVideoCallStream,
  releaseAudioStream,
  releaseVideoCallStream,
} from "../../utils/helpers/mediaStream";
import { getBrowserName } from "./getBrowser";

export const GRANTED = "granted";
export const PROMPT = "prompt";
export const ERROR_VIDEO_CALL_PERMISSION =
  "Permissions for microphone and/or camera are denied. Please go to your browser settings and enable them to be able to make a call.";
export const ERROR_VOICE_CALL_PERMISSION =
  "Permissions for microphone is denied. Please go to your browser settings and enable it to be able to make a call.";

const checkFireFoxMicPermissions = async () => {
  try {
    await getAudioStream().then(function (stream) {
      releaseAudioStream();
    });
    return GRANTED;
  } catch (error) {
    return ERROR_VOICE_CALL_PERMISSION;
  }
};

export const checkFireFoxVideoCallPermissions = async () => {
  try {
    await getVideoCallStream(undefined, undefined).then(function (stream) {
      releaseVideoCallStream();
    });
    return GRANTED;
  } catch (error) {
    return ERROR_VIDEO_CALL_PERMISSION;
  }
};

export const checkMicPermissions = async (checkPromptState = true) => {
  return getBrowserName(navigator.userAgent) !== "Firefox"
    ? await checkAllBrowserMicPermissions(checkPromptState)
    : await checkFireFoxMicPermissions();
};

const checkAllBrowserMicPermissions = async (checkPromptState: boolean) => {
  if (!navigator.permissions?.query) return PROMPT;
  const micPermission = await navigator.permissions.query({
    name: "microphone" as PermissionName,
  });

  if (
    micPermission.state === GRANTED ||
    (checkPromptState && micPermission.state === PROMPT)
  ) {
    return micPermission.state;
  } else {
    return ERROR_VOICE_CALL_PERMISSION;
  }
};

export const checkVideoCallPermissions = async (checkPromptState = true) => {
  return getBrowserName(navigator.userAgent) !== "Firefox"
    ? await checkAllBrowserVideoCallPermissions(checkPromptState)
    : await checkFireFoxVideoCallPermissions();
};

const checkAllBrowserVideoCallPermissions = async (
  checkPromptState: boolean
) => {
  const error = ERROR_VIDEO_CALL_PERMISSION;

  const micPermission = await checkMicPermissions(checkPromptState);

  if (
    micPermission !== GRANTED &&
    (!checkPromptState || micPermission !== PROMPT)
  ) {
    return error;
  }

  if (!navigator.permissions?.query) return PROMPT;

  const cameraPermission = await navigator.permissions.query({
    name: "camera" as PermissionName,
  });

  if (
    cameraPermission.state === GRANTED ||
    (checkPromptState && cameraPermission.state === PROMPT)
  ) {
    return cameraPermission.state;
  } else {
    return error;
  }
};

export async function requestAudioCallPermission() {
  try {
    await getAudioStream().then(function (stream) {
      releaseAudioStream();
    });
  } catch (error) {
    console.error("Error requesting microphone permission:", error);
  }
}

export async function requestVideoCallPermissions() {
  try {
    await getVideoCallStream(undefined, undefined).then(function (stream) {
      releaseVideoCallStream();
    });
  } catch (error) {
    console.error("Error requesting microphone or camera permissions:", error);
  }
}

export async function racePromisesWithTimeout<T>(
  promises: Promise<T>[],
  timeout: number
): Promise<T[]> {
  try {
    const result = await Promise.race([
      Promise.all(promises),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Timeout")), timeout)
      ),
    ]);
    return result as T[];
  } catch (error) {
    throw error;
  }
}
