import { proxyToRaw } from "@/utils/";
import { conversationsState } from "../ConversationState";

export async function updateConversationInDatabase(
  conversationId: string,
  dbConversationIdKeyToRemove?: string
) {
  const conversation = conversationsState.conversations.get(conversationId);
  console.log("Updating database for conversation ", conversation);

  if (!conversation) {
    console.error("No conversation found");
    return;
  }
  const tx = (await window.getVerseDb()).transaction(
    "conversations",
    "readwrite"
  );
  await tx.store.put(
    proxyToRaw(proxyToRaw(conversation.serialize())),
    conversationId
  );

  if (
    dbConversationIdKeyToRemove &&
    conversationId !== dbConversationIdKeyToRemove
  ) {
    console.log("Deleting old conversation ", dbConversationIdKeyToRemove);
    await tx.store.delete(dbConversationIdKeyToRemove);
  }

  await tx.done;
}
