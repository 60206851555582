import { colors } from "@/styles/global.styles";
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  Placement,
  shift,
  useFloating,
  useHover,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react";
import { useRef, useState, type ReactNode } from "react";
import { placementOrigins } from "./Popover";

type FloatingTooltipProps = {
  tooltipContent: ReactNode;
  children: ReactNode;
  placement?: Placement;
};

// TODO rename this component to Tooltip after removing the old one
export default function FloatingTooltip({
  tooltipContent,
  placement = "top",
  children,
}: FloatingTooltipProps) {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef<SVGSVGElement | null>(null);

  const { refs, floatingStyles, context, middlewareData } = useFloating({
    open: isOpen,
    placement,
    onOpenChange: setIsOpen,
    middleware: [
      offset(10),
      flip({
        padding: 5,
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "start",
      }),
      shift({
        padding: 5,
      }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  const animationDuration = 100;
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: "scale(0.9) translateZ(0)",
    },
    close: {
      opacity: 0,
      transform: "scale(0.9) translateZ(0)",
    },
    common: ({ placement }) => {
      const [x, y] = placementOrigins[placement];

      const shiftX = (middlewareData.shift?.x ?? 0) - 0;
      const shiftY = (middlewareData.shift?.y ?? 0) - 0;
      const res = {
        transformOrigin: `calc(${x} - ${shiftX}px) calc(${y} - ${shiftY}px)`,
      };

      return res;
    },
    duration: animationDuration,
  });

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
      {isMounted && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <div
            style={transitionStyles}
            className="z-50 rounded-md bg-primaryBackgroundLighter px-3 py-[10px] text-sm text-white shadow-md"
          >
            {tooltipContent}
            <FloatingArrow
              ref={arrowRef}
              context={context}
              fill={colors.primaryBackgroundLighter}
            />
          </div>
        </div>
      )}
    </>
  );
}
