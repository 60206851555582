import type { GlobalProps } from "@emotion/react";
import Color from "color";

export const colors = {
  primaryBackground: "#1A2026",
  primaryBackgroundLighter: "#21272D",
  secondaryBackground: "#2E3237",
  secondaryBackgroundLighter: "#35383D",
  tertiaryBackground: "#000E1A",
  fourthBackground: "hsl(213,13%,16%)", // new Color(colors.secondaryBackground).darken(0.15).toString()
  primaryTextColor: "#FFFFFF",
  secondaryTextColor: "#8C8F92",
  secondaryTextColorLighter: "hsl(210,2.7%,64.5%)", // new Color(colors.secondaryTextColor).lighten(0.15).toString()
  tertiaryTextColor: "hsl(206,3%,30%)",
  primaryAccentColor: "#DC3851",
  primaryAccentColorDarker: "#C6334B",
  secondaryAccentColor: "#12B891",
  tertiaryAccentColor: "#292E33",

  // TODO: maybe incorporate these onboarding colors into the main theme
  onboardingPrimaryBg: "#17181d",
  onboardingSecondaryBg: "#28292E",
  onboardingTertiaryBg: "#333333",
  onboardingQuaternaryBg: "#27282C",
  onboardingPrimaryText: "#FFFFFF",
  onboardingSecondaryText: "#8C8F92",
  onboardingPrimaryAccent: "#DC3851",
  onboardingPrimaryAccentSmallText: "#E25A66",
  onboardingSecondaryAccent: "#fb626b",
  onboardingTertiaryAccent: "#b18de0",
  onboardingQuaternaryAccent: "#a260f7",
  onboardingQuinaryAccent: "#12B891",
} as const;

export const scrollbarWidth = "6px";

function addAlphaVariableToColor(color: string, alphaVar: string) {
  const c = new Color(color).alpha(1).toString();
  return c.substring(0, c.length - 1) + `, var(${alphaVar}))`;
}

export const globalStyles: GlobalProps["styles"] = {
  "@import":
    'url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap")',

  "*, *::before, *::after": {
    boxSizing: "border-box",
  },
  body: {
    "--scrollbar-alpha": "1",
    "--scrollbar-alpha-hover": "1",
    overflowX: "hidden",
  },
  "button, input": {
    background: "none",
    color: "inherit",
    border: "none",
    padding: "0",
    margin: "0",
    font: "inherit",
    outline: "inherit",
  },
  button: {
    cursor: "pointer",
  },
  hr: {
    border: "none",
    height: "2px",
    color: colors.tertiaryTextColor,
    background: "currentcolor",
    marginInline: "0",
    marginBlock: "0",
  },
  a: {
    width: "fit-content",
  },
  ":where(a:link)": {
    color: "inherit",
  },
  ".hide-scrollbar": {
    scrollbarWidth: "none", // Firefox
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      width: "0",
      height: "0",
    },
  },
  "::-webkit-scrollbar": {
    width: scrollbarWidth,
  },
  "::-webkit-scrollbar-track": {
    backgroundColor: addAlphaVariableToColor(
      colors.secondaryBackground,
      "--scrollbar-alpha"
    ),
    borderRadius: "6px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: addAlphaVariableToColor(
      colors.secondaryTextColor,
      "--scrollbar-alpha"
    ),
    borderRadius: "6px",
  },
  "::-webkit-scrollbar-thumb:hover": {
    backgroundColor: addAlphaVariableToColor(
      new Color(colors.secondaryTextColor).lighten(0.25).toString(),
      "--scrollbar-alpha-hover"
    ),
  },
  /* Chrome, Safari, Edge, Opera */
  "input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: "0",
  },

  "input::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: "0",
  },
  /* Firefox */
  "input[type=number]": {
    MozAppearance: "textfield",
  },
  "@keyframes fade-in": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  "@keyframes fade-out": {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0,
    },
  },
};
