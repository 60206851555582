import {
  WebGwContactList,
  wrapToWebGwContactList,
} from "../helpers/WebGwContact";
import { isSamePhoneNumber } from "../messaging/conversation/conversationUtils/phoneNumberUtils";
import { blockedContactsQuery, contactsQuery } from "../queries/contacts";
import { queryClient } from "../queryClient";

export function filterContactWithCaps(contactList?: WebGwContactList | null) {
  if (!contactList) {
    return new WebGwContactList();
  }

  return contactList.filter((contact) => contact.isVerse) as WebGwContactList;
}

type QueryReturn = Awaited<ReturnType<typeof contactsQuery.queryFn>>;

export function getLoadedContacts() {
  return wrapToWebGwContactList(
    queryClient.getQueryData<QueryReturn>(contactsQuery.queryKey)
  );
}

export async function getContactsAsync() {
  return wrapToWebGwContactList(await queryClient.fetchQuery(contactsQuery));
}

export function setLoadedContacts(data: WebGwContactList) {
  return queryClient.setQueryData(contactsQuery.queryKey, data);
}

export async function refreshContacts() {
  await queryClient.invalidateQueries({ queryKey: contactsQuery.queryKey });
}

export async function clearBlockedContactsCache() {
  await queryClient.invalidateQueries({ queryKey: ["blockedContacts"] });
}

type QueryReturnBlockedContacts = Awaited<
  ReturnType<typeof blockedContactsQuery.queryFn>
>;

export async function getBlockedContacts() {
  return queryClient.fetchQuery(blockedContactsQuery);
}

export async function isPhoneNumberBlocked(phoneNumber: string) {
  const blockedList = await getBlockedContacts();

  if (blockedList) {
    return (
      blockedList.findIndex((current) =>
        isSamePhoneNumber(current, phoneNumber)
      ) !== -1
    );
  }

  return false;
}
