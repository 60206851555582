// Define log levels
const LOG_LEVELS = {
  NONE: 0,
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  LOG: 4,
  DEBUG: 5,
};

// Set the current log level
let currentLogLevel: number = Number(window._env_.LOG_LEVEL) ?? 5;
if (!currentLogLevel) {
  currentLogLevel = 5;
}
console.info("currentLogLevel:", currentLogLevel);

/** Signature of a logging function */
export interface LogFn {
  (message?: any, ...optionalParams: any[]): void;
}
const NO_OP: LogFn = (message?: any, ...optionalParams: any[]) => {};

currentLogLevel < LOG_LEVELS.DEBUG == true
  ? (console.debug = function (...args) {})
  : undefined;
currentLogLevel < LOG_LEVELS.LOG == true
  ? (console.log = function (...args) {})
  : undefined;
currentLogLevel < LOG_LEVELS.INFO == true
  ? (console.info = function (...args) {})
  : undefined;
currentLogLevel < LOG_LEVELS.WARN == true
  ? (console.warn = function (...args) {})
  : undefined;
currentLogLevel < LOG_LEVELS.ERROR == true
  ? (console.error = function (...args) {})
  : undefined;
