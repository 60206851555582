import { colors } from "@/styles/global.styles";
import { ease } from "@/utils/ease";
import { atoms } from "@/utils/helpers/atoms";
import { captureFrameEverySecond } from "@/utils/hooks/useCall";
import { keyframes } from "@emotion/react";
import Color from "color";
import { AnimatePresence, motion } from "framer-motion";
import { useSetAtom } from "jotai";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ButtonBar, CallOverlayProps } from ".";
import pauseIcon from "../../assets/icon-pause.svg";
import WebGwContact from "../../utils/helpers/WebGwContact";
import { Avatar } from "../shared/Avatar";
import {
  DraggableOverlay,
  fullscreenOverlaySize,
  overlayTransition,
} from "../shared/DraggableOverlay";
import Timer from "../shared/Timer";
import { CloseOverlayButtonBox } from "./Overlay";

type OverlayProps = CallOverlayProps & {
  videoRef?: React.RefObject<HTMLVideoElement>;
  outgoingVideoRef?: React.RefObject<HTMLVideoElement>;
  rotationDegree?: number;
};

const VIDEO_WIDTH = 1280;
const VIDEO_HEIGHT = 720;

function OnHoldIndicator({ onHold }: { onHold: boolean }) {
  return (
    <AnimatePresence>
      {onHold && (
        <motion.div
          css={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 14, 26, 0.8)",
            backdropFilter: "blur(10px)",
            zIndex: 1,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: overlayTransition }}
          transition={{ duration: 0.75, ease: ease }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "2vh",
              height: "100%",
              color: colors.primaryTextColor,
            }}
          >
            <img css={{ height: "5em" }} src={pauseIcon} />
            <div
              css={{
                borderRadius: "10em",
                padding: "0.8em",
                backgroundColor: new Color(colors.secondaryAccentColor)
                  .alpha(0.6)
                  .toString(),
                // pulse background color
                animation: `${keyframes({
                  "50%": {
                    backgroundColor: new Color(colors.secondaryAccentColor)
                      .alpha(0.4)
                      .toString(),
                  },
                })} 2s ease-in-out infinite`,
              }}
            >
              Your call is currently on hold...
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

function VideoContactAvatar({
  contact,
  underAvatar,
  isVideoWallCall,
  isFrontRowCall,
}: {
  contact: WebGwContact;
  underAvatar?: React.ReactNode;
  isVideoWallCall?: boolean;
  isFrontRowCall?: boolean;
}) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "1.5em",
      }}
    >
      <Avatar
        contact={contact}
        style={
          contact.photo || !contact.initials
            ? { width: "3.125em", height: "3.125em" }
            : { width: "2em", height: "2em", fontSize: "1.9535em" }
        }
      />
      <div
        css={{
          whiteSpace: "nowrap",
          color: "white",
          display: "flex",
          flexDirection: "column",
          gap: "0.75em",
        }}
      >
        <span
          css={{
            fontSize: "2em",
            textShadow: "2px 2px 6px rgb(0,0,0,0.5)",
          }}
        >
          {contact.noNameReturnPhoneNumber()}
        </span>
        <span>{underAvatar}</span>
      </div>
    </div>
  );
}

export function VideoCallOverlay({
  isFullScreen,
  toggleFullScreen,
  open,
  callActive,
  callMuted,
  rotationDegree,
  contact,
  videoRef,
  outgoingVideoRef,
  muteCall,
  endCall,
  time,
  showNotImplemented,
  isPaused,
  isVideoWallCall,
  isFrontRowCall,
  isFeaturedCaller,
}: OverlayProps) {
  const [isHovered, setIsHovered] = useState(false);
  const setPreviewFrame = useSetAtom(atoms.odience.usePreviewFrame);
  useLayoutEffect(() => {
    setIsHovered(false);
  }, [isFullScreen]);
  const [isButtonVisible, _setIsButtonVisible] = useState(true);

  const TIMEOUT_HIDE_BUTTONS_MS = 2000;
  const hideButtonsTimer = useRef<number>();
  const setIsButtonVisible = (val: typeof isButtonVisible) => {
    _setIsButtonVisible(val);
    if (callActive) {
      clearTimeout(hideButtonsTimer.current);
      hideButtonsTimer.current = +setTimeout(() => {
        _setIsButtonVisible(false);
      }, TIMEOUT_HIDE_BUTTONS_MS);
    }
  };

  useLayoutEffect(() => {
    // Buttons are visible by default
    if (open) {
      setIsButtonVisible(true);
    }
  }, [open]);

  const handleOnMouseMove = () => {
    if (!callActive) {
      return;
    }

    setIsButtonVisible(true);
  };

  const isPortrait = rotationDegree === 270 || rotationDegree === 90;

  useEffect(() => {
    const handleResize = () => {
      const video = videoRef?.current;
      const outgoingVideo = outgoingVideoRef?.current;
      const videoContainer = video?.parentElement?.parentElement?.parentElement;

      if (!video || !videoContainer || !outgoingVideo) return;

      const containerWidth = videoContainer.offsetWidth;
      const containerHeight = videoContainer.offsetHeight;

      // Calculate scaling factor based on container size
      const scaleX = containerWidth / VIDEO_WIDTH;
      const scaleY = containerHeight / VIDEO_HEIGHT;

      // Adjust video dimensions based on rotation and fullscreen state
      const videoWidth = isPortrait ? containerHeight : containerWidth;

      const videoHeight = isPortrait ? containerWidth : containerHeight;

      // Set video size and alignment
      video.style.width = `${videoWidth}px`;
      video.style.height = `${videoHeight}px`;
      video.parentElement.style.justifyContent = isPortrait
        ? "start"
        : "center";

      // Adjust outgoing video size when call is not active
      if (!callActive && !isVideoWallCall) {
        outgoingVideo.style.width = `${VIDEO_WIDTH * scaleX}px`;
        outgoingVideo.style.height = `${VIDEO_HEIGHT * scaleY}px`;
      }

      // In pip mode, the video takes the full parent size. Hack to use the same border to prevent changing the UI.
      if (!isFullScreen) {
        video.style.borderRadius = getComputedStyle(
          video.parentElement.parentElement
        ).getPropertyValue("border-radius");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [
    isVideoWallCall,
    videoRef,
    outgoingVideoRef,
    rotationDegree,
    isFullScreen,
    callActive,
    open,
  ]);

  const handleFrameCapture = (base64Image: string) => {
    setPreviewFrame(base64Image);
  };

  useEffect(() => {
    let cleanup: (() => void) | undefined;

    if (isVideoWallCall && outgoingVideoRef?.current) {
      cleanup = captureFrameEverySecond(outgoingVideoRef, handleFrameCapture);
    }

    return () => {
      cleanup?.();
    };
  }, [isVideoWallCall, outgoingVideoRef]);

  const VideoWallText = () => {
    if (isFeaturedCaller) {
      return "You are currently the Featured Caller";
    }

    if (isVideoWallCall) {
      return "Live on the Video Wall";
    }

    if (isFrontRowCall) {
      return "You're on Front Row!";
    }
  };

  const VideoWallColor = () => {
    if (isFeaturedCaller || isFrontRowCall) {
      return "#F5BB56";
    }

    if (isVideoWallCall) {
      return colors.secondaryAccentColor;
    }
  };

  const isOdienceCall = isVideoWallCall || isFrontRowCall;

  return (
    <DraggableOverlay
      open={open}
      isFullScreen={isFullScreen}
      onMouseMove={handleOnMouseMove}
      onDoubleClick={() => {
        if (!isFullScreen) toggleFullScreen?.();
      }}
      onBackgroundDoubleClick={toggleFullScreen}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      component="call"
    >
      <div
        style={{
          ...(isOdienceCall && { border: `2px solid ${VideoWallColor()}` }),
          position: "relative",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        {isOdienceCall && (
          <div
            css={{
              height: "1.5em",
              width: "100%",
              fontSize: "1rem",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              color: colors.primaryTextColor,
              backgroundColor: VideoWallColor(),
              zIndex: 9998, // One less than the close button
            }}
          >
            {VideoWallText()}
          </div>
        )}

        <div
          css={{
            overflow: "hidden",
            boxShadow: "0px 4px 8px #00000029",
            backgroundColor: colors.primaryBackgroundLighter,
          }}
          style={
            isFullScreen
              ? {
                  width: fullscreenOverlaySize.width,
                  height: fullscreenOverlaySize.height,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "top",
                  alignItems: "center",
                }
              : { width: "320px", height: "180px" }
          }
        >
          <OnHoldIndicator onHold={isPaused} />
          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              alignItems: "center",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
            }}
          >
            <video
              ref={videoRef}
              autoPlay
              playsInline
              muted
              controls={false}
              disablePictureInPicture
              css={{
                objectFit: "contain",
                position: "relative",
                backgroundColor: colors.primaryBackgroundLighter,
                pointerEvents: "none",
              }}
              style={{ transform: `rotate(${rotationDegree ?? 0}deg)` }}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        </div>

        {/* Picture-in-Picture (PIP)  only show when phone call is answered*/}
        <div
          style={{
            position: "absolute",
            backgroundColor: "rgb(0,0,0,0.25)",
            overflow: "hidden",
            zIndex: 2,
            // Video wall call never has incoming video, keep the local video taking the full space
            ...(!callActive || isVideoWallCall
              ? {
                  left: "0px",
                  bottom: "0px",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "top",
                  alignItems: "center",
                }
              : {
                  left: "40px",
                  bottom: "40px",
                  width: "200px",
                  height: "150px",
                }),
            ...(!isFullScreen &&
              callActive &&
              !isVideoWallCall && {
                display: "none",
              }),
          }}
        >
          <video
            ref={outgoingVideoRef}
            autoPlay
            playsInline
            muted
            controls={false}
            disablePictureInPicture
            style={{
              objectFit: "cover",
              pointerEvents: "none",
              ...(callActive ? { width: "100%", height: "100%" } : {}),
            }}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
          />
        </div>

        {!isFullScreen ? (
          <motion.div
            css={{
              position: "absolute",
              bottom: "0",
              left: "0",
              padding: "0.5em",
              opacity: "1",
              zIndex: 3,
            }}
            initial={{ y: "100%" }}
            animate={{ y: isButtonVisible && isHovered ? 0 : "100%" }}
          >
            <ButtonBar
              callActive={callActive}
              callMuted={callMuted}
              muteCall={muteCall}
              endCall={endCall}
              showNotImplemented={showNotImplemented}
              isFullScreen={isFullScreen}
              isOdienceCall={isOdienceCall}
            />
          </motion.div>
        ) : (
          <>
            <div
              css={{
                top: "2.5em",
                left: "2.5em",
                position: "absolute",
                display: "flex",
                gap: "2em",
                color: colors.primaryTextColor,
                zIndex: 3,
              }}
            >
              {contact && (
                <VideoContactAvatar
                  contact={contact}
                  underAvatar={
                    !callActive ? "Calling..." : <Timer time={time} />
                  }
                  isVideoWallCall={isVideoWallCall}
                  isFrontRowCall={isFrontRowCall}
                />
              )}
            </div>
            <motion.div
              style={{
                minHeight: "100%",
                position: "relative",
                zIndex: 3,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: isButtonVisible ? 1 : 0 }}
            >
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  bottom: "40px",
                }}
              >
                <ButtonBar
                  isFullScreen
                  callActive={callActive}
                  callMuted={callMuted}
                  muteCall={muteCall}
                  endCall={endCall}
                  showNotImplemented={showNotImplemented}
                  isOdienceCall={isOdienceCall}
                />
              </div>
            </motion.div>
          </>
        )}

        <CloseOverlayButtonBox
          isFullScreen={isFullScreen}
          isHovered={isHovered}
          toggleFullScreen={toggleFullScreen}
        />
      </div>
    </DraggableOverlay>
  );
}
