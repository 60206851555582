import { baseWebGwUrl } from "..";
import { ContactsResponse, WebGwContactList } from "./WebGwContact";
import { getLocalAccessToken, getLocalUser } from "./localstorage";

export async function fetchContacts() {
  const user = getLocalUser();
  const accessToken = getLocalAccessToken();

  if (!user || !accessToken) {
    console.error("User or access token not found");
    return null;
  }

  const res = await fetch(
    new URL(
      `/addressbook/v1/${user}/contacts?access_token=${accessToken}`,
      baseWebGwUrl
    ),
    { cache: "no-store" }
  );

  if (!res.ok) {
    console.error("Failed to fetch contacts", res);
    return null;
  }

  const contacts = (await res.json()) as ContactsResponse;

  return new WebGwContactList(contacts);
}

export async function contactListCapabilities() {
  const user = getLocalUser();
  const accessToken = getLocalAccessToken();

  const contactList = await fetchContacts();

  if (!user || !accessToken || !contactList) {
    console.error("User or access token not found");
    return;
  }

  const remoteNumbers: string[] = [];

  for (let i = 0; i < contactList.length; i++) {
    remoteNumbers.push(`tel:${contactList[i].id}`);
  }

  await fetch(
    new URL(
      `/capabilitydiscovery/v1/${user}/adhocContactListCapabilities?access_token=${accessToken}`,
      baseWebGwUrl
    ),
    {
      method: "POST",
      referrerPolicy: "no-referrer-when-downgrade",
      cache: "no-store",
      body: JSON.stringify({
        contactId: remoteNumbers,
      }),
    }
  );
}

export async function getBlockedContactsList() {
  const user = getLocalUser();
  const accessToken = getLocalAccessToken();

  if (!user || !accessToken) {
    console.error("User or access token not found");
    return null;
  }

  const res = await fetch(
    new URL(
      `/client/v1/getBlockedList?access_token=${accessToken}`,
      baseWebGwUrl
    ),
    { cache: "no-store" }
  );

  if (!res.ok) {
    console.error("Failed to fetch contacts", res);
    return null;
  }

  const jsonResponse = await res.json();

  console.log("getBlockedList(): jsonResponse=", jsonResponse);

  return jsonResponse;
}

export async function blockContact(blockContact: string): Promise<boolean> {
  const user = getLocalUser();
  const accessToken = getLocalAccessToken();

  if (!user || !accessToken) {
    console.error("User or access token not found");
    return false;
  }

  const res = await fetch(
    new URL(
      `/client/v1/blockContact?access_token=${accessToken}&block_contact=${encodeURIComponent(
        blockContact
      )}`,
      baseWebGwUrl
    ),
    {
      method: "PUT",
      cache: "no-store",
    }
  );

  if (!res.ok) {
    console.error("Failed to fetch contacts", res);
    return false;
  }

  return true;
}

export async function unblockContact(blockContact: string): Promise<boolean> {
  const user = getLocalUser();
  const accessToken = getLocalAccessToken();

  if (!user || !accessToken) {
    console.error("User or access token not found");
    return false;
  }

  const res = await fetch(
    new URL(
      `/client/v1/blockContact?access_token=${accessToken}&block_contact=${encodeURIComponent(
        blockContact
      )}`,
      baseWebGwUrl
    ),
    {
      method: "DELETE",
      cache: "no-store",
    }
  );

  if (!res.ok) {
    console.error("Failed to fetch contacts", res);
    return false;
  }

  return true;
}

type SetData = (data: string[]) => void;
export const fetchBlockedContactsList = (setData: SetData) => {
  getBlockedContactsList().then((res: string[]) => {
    console.log("BlockedListUris:", res);
    setData(res);
  });
};
