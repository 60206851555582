import { ReconnectingAnimation } from "@/components/shared/Loaders/ReconnectingAnimation";
import toast from "react-hot-toast";
import { setForcedSignedOut } from "../localstorage";

export const handleSessionExpired = () => {
  setForcedSignedOut(true);
};

export const showSessionExpiredToast = () => {
  toast("Your session has expired, please log in again.", {
    style: { backgroundColor: "#2E3237", color: "#FFFFFF" },
    duration: 5000,
    icon: <ReconnectingAnimation />,
    id: "toastSessionExpiredId",
  });
};
