import styled from "@emotion/styled";

import { css } from "@emotion/react";
import { colors } from "../../styles/global.styles";

export const buttonCommon = css({
  borderRadius: "6px",
  ":hover": {
    backgroundColor: colors.secondaryBackground,
  },
  transition: "all 0.25s ease",
  padding: "0.3em",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const Button = styled.button(buttonCommon);

export const PrimaryButton = styled.button([
  buttonCommon,
  {
    backgroundColor: colors.primaryAccentColor,
    ":hover": {
      backgroundColor: colors.primaryAccentColorDarker,
    },
  },
]);

export const IconButton = styled.button([
  buttonCommon,
  {
    color: colors.primaryAccentColor,
    lineHeight: "0",
    width: "2em",
    height: "2em",
    ":disabled": {
      color: colors.secondaryTextColor,
      cursor: "default",
      backgroundColor: "initial",
      ":hover": {
        color: colors.tertiaryTextColor,
      },
    },
  },
]);

export const IconButtonBox = styled.span([
  buttonCommon,
  {
    color: colors.primaryAccentColor,
    display: "inline-block",
    lineHeight: "0",
    width: "2em",
    height: "2em",
    ":disabled": {
      color: colors.secondaryTextColor,
      cursor: "default",
      backgroundColor: "initial",
      ":hover": {
        color: colors.tertiaryTextColor,
      },
    },
  },
]);
