import { useUnreadCallsCount } from "@/utils/hooks/useUnreadCalls";
import { useUnreadMessagesCount } from "@/utils/messaging/conversation/conversationUtils/useUnreadMessages";
import { memo, useEffect } from "react";

function useNotificationCount() {
  const unreadCallsCount = useUnreadCallsCount();
  const unreadMessagesCount = useUnreadMessagesCount();
  return unreadCallsCount + unreadMessagesCount;
}

const initialDocumentTitle = "VERSE";

export function resetDocumentTitle() {
  document.title = initialDocumentTitle;
}

export default memo(
  function DocumentTitle() {
    const notificationCount = useNotificationCount();

    useEffect(() => {
      if (notificationCount === 0) {
        resetDocumentTitle();
      } else {
        document.title = `(${notificationCount}) ${initialDocumentTitle}`;
      }
    }, [notificationCount]);

    return null;
  },
  () => true
);
