import { colors } from "@/styles/global.styles";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { buttonCommon } from "../shared/Button";

export const ControlBar = styled.div({
  color: colors.primaryTextColor,
  gap: 0,
  borderRadius: "10px",
  overflow: "hidden",
  display: "flex",
  flexWrap: "nowrap",
});

const ControlButtonCommon = css(buttonCommon, {
  padding: "0.5em",
  borderRadius: "unset",
  ":disabled": {
    opacity: ".5",
  },
  backgroundColor: colors.secondaryBackground,
  ":hover": {
    backgroundColor: colors.secondaryBackgroundLighter,
  },
});

export const ControlButtonLarge = css(ControlButtonCommon, {
  padding: "1em",
  height: "4.15em",
  width: "4.15em",
});

export const ControlButtonSmall = ControlButtonCommon;

export const EndCallButtons = css({
  display: "flex",
  justifyContent: "center",
  gap: "1vh",
  backgroundColor: colors.secondaryBackground,
  minHeight: "3em",
  maxHeight: "5em",
  width: "100%",
  borderRadius: "10px",
  alignItems: "center",
});
