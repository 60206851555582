import { useMemo } from "react";
import { useSnapshot } from "valtio";
import { callsState } from "../calls/callState";

export function useUnreadCallsCount() {
  const { calls } = useSnapshot(callsState);

  return useMemo(() => {
    return (
      calls
        .values()
        // Show count for non read incoming missed calls
        .filter(
          (c) =>
            !c.isRead &&
            c.callLogPayload.type === "missed" &&
            c.message.Direction === "In"
        )
        .count()
    );
  }, [calls]);
}
