import { baseNMSUrl } from "..";
import { getLocalUser } from "../helpers/localstorage";

export async function deleteMessages(messagesIds: string[]) {
  const phoneNumber = getLocalUser();
  if (!phoneNumber) {
    console.error("No phone number provided");
    return false;
  }
  const encodedPhoneNumber = encodeURIComponent("tel:" + phoneNumber);
  const res = await fetch(
    new URL(
      `/nms/v1/store/${encodedPhoneNumber}/objects/operations/bulkDelete`,
      baseNMSUrl
    ),
    {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        bulkDelete: {
          selectionCriteria: {
            searchCriteria: {
              operator: "Or",
              criterion: messagesIds.map((msgId) => ({
                name: "imdn.Message-ID",
                value: msgId,
                type: "Attribute",
              })),
            },
          },
        },
      }),
    }
  ).catch((e) => {
    console.error(e);
    return null;
  });
  return res?.ok ?? false;
}
