import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { wrapToWebGwContactList } from "../helpers/WebGwContact";
import { contactsQuery } from "../queries/contacts";

export function useContactsSuspense() {
  return wrapToWebGwContactList(useSuspenseQuery(contactsQuery).data);
}

export function useContacts() {
  return wrapToWebGwContactList(useQuery(contactsQuery).data);
}
