import { css } from "@emotion/react";
import { useEffect } from "react";
import { Link, useRouteError } from "react-router-dom";
import { paths } from "./routerPaths";
import { colors } from "./styles/global.styles";
import {
  getReloadedInLast10Seconds,
  setReloadedInLast10Seconds,
} from "./utils/helpers/sessionStorage";

export default function RouterErrorHandler() {
  const error = useRouteError();

  const includesFailedToFetch =
    error instanceof Error &&
    error.message.includes("Failed to fetch dynamically imported module");

  useEffect(() => {
    if (!includesFailedToFetch) return;

    const doReload = () => {
      setReloadedInLast10Seconds(new Date());

      window.location.reload();
    };

    const reloadedInLast10SecondsStr = getReloadedInLast10Seconds();
    const reloadedInLast10Seconds =
      reloadedInLast10SecondsStr &&
      new Date(reloadedInLast10SecondsStr) >
        new Date(new Date().getTime() - 10_000);

    if (reloadedInLast10Seconds) {
      console.error(
        "Failed to fetch dynamically imported module. Reloaded in last 10 seconds. Aborting reload."
      );
      setTimeout(doReload, 10_000);
    } else {
      doReload();
    }
  }, [error]);

  if (includesFailedToFetch) {
    return null;
  }

  if (import.meta.env.DEV) {
    console.error("RouterErrorHandler:", error);

    return (
      <div css={styles.root}>
        <div css={styles.container}>
          <h2 css={styles.heading}>Error</h2>
          <p css={styles.error}>
            {error instanceof Error ? error.message : (error as any)}
          </p>
          <Link to={paths.root} css={styles.button}>
            Go to Home
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div css={styles.root}>
      <div css={styles.container}>
        <h2 css={styles.heading}>An error has occurred</h2>
        <p css={styles.error}>Please refresh the page.</p>
        <Link to={paths.root} css={styles.button}>
          Go to Home
        </Link>
      </div>
    </div>
  );
}

const styles = {
  root: css({
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  container: css({
    width: "100%",
    maxWidth: 600,
    margin: "auto",
    padding: 20,
    backgroundColor: colors.primaryBackground,
    borderRadius: 5,
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    display: "flex",
    flexDirection: "column",
    gap: "1em",
  }),
  heading: css({
    color: colors.primaryAccentColor,
    margin: 0,
    fontSize: 24,
    fontWeight: "bold",
  }),
  error: css({
    color: colors.primaryTextColor,
    fontSize: 16,
  }),
  button: css({
    color: colors.primaryTextColor,
    backgroundColor: colors.primaryAccentColor,
    fontSize: 16,
    padding: "0.5em 1em",
    borderRadius: "5px",
    width: "fit-content",
  }),
};
