import styles from "./ThreeLines.module.css";

export function ThreeLinesLoader() {
  return (
    <>
      <span className={styles.bar}></span>
      <span className={styles.bar}></span>
      <span className={styles.bar}></span>
    </>
  );
}

export function ThreeLinesLoaderBox({
  absolute,
  ...props
}: React.ComponentProps<"div"> & { absolute?: boolean }) {
  return (
    <div
      {...props}
      css={[
        // @ts-ignore
        props.css,
        {
          height: "100%",
          width: "100%",
          opacity: "0.6",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        absolute && {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
      ]}
    >
      <ThreeLinesLoader />
    </div>
  );
}
