import { useMemo, useReducer } from "react";
import { subscribe, useSnapshot } from "valtio";
import { conversationsState } from "../ConversationState";

export function useUnreadMessagesCount() {
  const { conversations } = useSnapshot(conversationsState);

  const [key, rerender] = useReducer((x) => x + 1, 0);

  return useMemo(() => {
    let unreadCount = 0;
    for (const conversation of conversations.values()) {
      const lastInMessage = conversation.getLastInMessage();
      if (lastInMessage && !lastInMessage.isDisplayedNotificationSent) {
        // if the last message is not read, check the rest of the messages to see if they are unread until we find a read message
        const messages = conversation.getMessages();
        for (let i = messages.length - 1; i >= 0; i--) {
          const message = messages[i];

          if (message.Direction !== "In") continue;

          if (!message.isDisplayedNotificationSent) {
            unreadCount++;
          } else {
            break;
          }
        }

        // subscribe to the last message to be able to update the unread count when the message is updated to be read
        const unsubscribe = subscribe(lastInMessage, () => {
          if (lastInMessage.isDisplayedNotificationSent) {
            rerender();
            unsubscribe();
          }
        });
      }
    }
    return unreadCount;
  }, [conversations, key]);
}
