import { css } from "@emotion/react";

export const PopupOverlayCSS = css({
  width: "25vw",
  height: "fit-content",
  backgroundColor: "#1A2026",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "1em",
  color: "white",
  padding: "2.5vw",
});

export const PopupOverlayButtonCSS = css({
  textDecoration: "none",
  border: "none",
  borderRadius: "10px",
  fontSize: "1rem",
  padding: "1vh 1vw",
});

export const PopupOverlayBodyCSS = css({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
});

export const PopupOverlayCloseButtonCSS = css({
  background: "none",
  border: "none",
  color: "white",
});
