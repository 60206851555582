import { UndefinedInitialDataOptions } from "@tanstack/react-query";
import {
  fetchContacts,
  getBlockedContactsList,
} from "../helpers/fetchContacts";

export const contactsQuery = {
  queryKey: ["contacts"],
  queryFn: async () => {
    const maxRetryAttempts = 5;
    const retryDelay = 5000;

    for (
      let retryAttempt = 0;
      retryAttempt < maxRetryAttempts;
      retryAttempt++
    ) {
      try {
        const contacts = await fetchContacts();
        if (contacts !== null) {
          return contacts;
        }
      } catch (error) {
        console.error("Unable to fetch contacts", error);
      }

      if (retryAttempt < maxRetryAttempts - 1) {
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
      }
    }

    return null;
  },
} as const satisfies UndefinedInitialDataOptions;

export const blockedContactsQuery = {
  // Right now there is no websocket in place to know when a contact has been blocked by another client using the same number, cache will be valid for only one second
  staleTime: 1000,
  queryKey: ["blockedContacts"],
  queryFn: async () => {
    try {
      return (await getBlockedContactsList()) as Promise<string[]>;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }

    return new Promise<string[]>(() => {});
  },
};
