import {
  DeleteOverlayBodyCSS,
  DeleteOverlayButtonCSS,
  DeleteOverlayCloseButtonCSS,
  MainPopupOverlayCSS,
} from "@/pages/conversationsScreen/ConversationScreen.style";
import { colors } from "@/styles/global.styles";
import CloseIcon from "@mui/icons-material/Close";
import PopupOverlay from "./PopupOverlay";

type ConfirmationPopupProps = {
  togglePopup: () => void;
  title: string;
  confirmationMessage: React.ReactNode;
  handleAction: (event: React.MouseEvent<HTMLButtonElement>) => void;
  primaryButtonText?: string;
  closeButtonActive?: Boolean;
  secondaryButtonActive?: Boolean;
};

const ConfirmationPopup = ({
  togglePopup,
  title,
  confirmationMessage,
  handleAction,
  primaryButtonText,
  closeButtonActive = true,
  secondaryButtonActive = true,
}: ConfirmationPopupProps) => {
  return (
    <PopupOverlay>
      <div css={MainPopupOverlayCSS}>
        <div css={DeleteOverlayBodyCSS}>
          <h3>{title}</h3>
          {closeButtonActive && (
            <button css={DeleteOverlayCloseButtonCSS} onClick={togglePopup}>
              <CloseIcon />
            </button>
          )}
        </div>

        <div css={{ marginBottom: "2vh", width: "100%", textAlign: "left" }}>
          {confirmationMessage}
        </div>

        <div
          css={{
            display: "flex",
            flexDirection: "row",
            gap: "1vw",
            justifyContent: "end",
            width: "100%",
          }}
        >
          {secondaryButtonActive && (
            <button
              css={[
                DeleteOverlayButtonCSS,
                {
                  backgroundColor: colors.secondaryBackground,
                  color: colors.primaryTextColor,
                },
              ]}
              onClick={togglePopup}
            >
              Cancel
            </button>
          )}
          <button
            css={[
              DeleteOverlayButtonCSS,
              {
                backgroundColor: colors.primaryAccentColor,
                color: colors.primaryTextColor,
              },
            ]}
            onClick={handleAction}
          >
            {primaryButtonText ? primaryButtonText : "Delete"}
          </button>
        </div>
      </div>
    </PopupOverlay>
  );
};

export default ConfirmationPopup;
