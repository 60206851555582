import {
  CapabilityFetchResult,
  CapabilityServerStatus,
  ServiceCapabilityArray,
} from "@/types/capabilities";
import { baseWebGwUrl, isValidPhoneNumber } from "@/utils";
import { getConfig } from "@/utils/helpers/config";
import { cleanPhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import { queryClient } from "@/utils/queryClient";
import { getDefaultStore } from "jotai";
import { atoms } from "../atoms";
import { isChatbot } from "../chatbots";
import { formatPhoneNumber } from "../formatPhoneNumber";
import { getLocalAccessToken, getLocalUser } from "../localstorage";
import { CapabilityNotification } from "../notificationChannel";
import { fetchWithTimeout } from "../Utils";

export const invalidateCapsCache = async (number) => {
  const queryKey = ["caps", formatPhoneNumber(number, "E164")];

  await queryClient.invalidateQueries({ queryKey });
};

export async function fetchCaps(remoteNumber: string, force: boolean = false) {
  if (!capsWarnings(remoteNumber)) return;

  return parsedCapsObj(remoteNumber, force);
}

export const getMyCapabilities = (
  key: string | number,
  configs: { config: any }
) => {
  const serviceConfig = configs.config;
  const serviceKey = "application/services/" + key;
  return serviceConfig[serviceKey];
};

export const checkPhoneNumberCapsContactList = async (number: string) => {
  return _checkPhoneNumberCaps(number, false);
};

export const checkPhoneNumberCaps = async (number: string) => {
  return _checkPhoneNumberCaps(number, true);
};

const _checkPhoneNumberCaps = async (number: string, force: boolean) => {
  try {
    const parsedObj = await parsedCapsObj(number, force);

    return {
      status: parsedObj.status,
      isRcs: parsedObj.caps?.contactServiceCapabilities.userType === "rcs",
      caps: parsedObj.caps?.contactServiceCapabilities.serviceCapability,
    };
  } catch (e) {
    console.error("Error checking phone number capabilities ", e);
    return { status: CapabilityServerStatus.UNKNOWN, isRcs: false, caps: [] };
  }
};

const capsWarnings = async (remoteNumber: string) => {
  const user = getLocalUser();
  if (!user) {
    console.error("No local user");
    return false;
  }

  const accessToken = getLocalAccessToken();
  if (!accessToken) {
    console.error("No local access token");
    return false;
  }

  const config = await getConfig();
  if (!config) {
    console.error("Null config");
    return false;
  }

  return true;
};

const parsedCapsObj = async (
  number: string,
  force = false
): Promise<CapabilityFetchResult> => {
  number = formatPhoneNumber(number, "E164");

  if (isChatbot(number)) {
    return {
      status: CapabilityServerStatus.FROM_CACHE,
      caps: {
        contactServiceCapabilities: {
          resourceURL: "",
          serviceCapability: [{ capabilityId: "Chat", status: "Enabled" }],
          uri: "",
          userType: "rcs",
        },
      },
    };
  }

  // Queries can only be made on international numbers
  if (!isValidPhoneNumber(number) || !number.startsWith("+")) {
    console.log("Non valid phone number ", number, " for caps query");

    // We update the caps empty to trigger the re-render of the ui that uses the atom for caps
    updateLocalCacheCapabilities(number);

    return {
      status: CapabilityServerStatus.FROM_CACHE,
      caps: {
        contactServiceCapabilities: {
          resourceURL: "",
          uri: "",
        },
      },
    };
  }

  const queryKey = ["caps", number];
  const res = await queryClient.fetchQuery({
    // 5 min cache time
    staleTime: 1000 * 60 * 5,
    queryKey: queryKey,
    queryFn: async () => {
      console.log("Fetching capabilities for ", number);

      // Cache will be handled by the server now
      const user = getLocalUser()!;
      const accessToken = getLocalAccessToken()!;

      const res = await fetchWithTimeout(
        new URL(
          `/capabilitydiscovery/v1/${user}/contactCapabilities/${await formatPhoneNumber(
            number,
            "SIP"
          )}?access_token=${accessToken}&_t=${Date.now()}&resFormat=json&force=${force}`,
          baseWebGwUrl
        ),
        {
          method: "GET",
          referrerPolicy: "no-referrer-when-downgrade",
          mode: "cors",
          credentials: "include",
          cache: "no-store",
        }
      );

      if (res) {
        let caps;
        try {
          caps = (await res.json()) as CapabilityNotification;
        } catch (err) {}

        return { status: res.status, caps };
      }

      return { status: CapabilityServerStatus.UNKNOWN };
    },
  });

  console.log("Capabilities for ", number, ": ", res);

  /**
   * The server will only refresh the caps if passing the force flag.
   * 202 case is for unknown number or caps expired (no caps returned in the end)
   * 200 is for known capabilities
   */
  const caps = {
    status:
      res.status === 202
        ? CapabilityServerStatus.UNKNOWN
        : CapabilityServerStatus.FROM_CACHE,
    caps: res.caps,
  };

  // Caps are currently known on the server, we update the local cache right away, an update may come on the notification channel later (only server knows the rules to refresh)
  if (caps.status === CapabilityServerStatus.FROM_CACHE) {
    updateLocalCacheCapabilities(
      number,
      caps.caps?.contactServiceCapabilities.serviceCapability
    );
  }

  return caps;
};

const defaultStore = getDefaultStore();

export async function updateLocalCacheCapabilities(
  number: string,
  serviceCapabilityArray?: ServiceCapabilityArray
) {
  console.log(
    "Updating local cache capabilities for ",
    number,
    ": ",
    serviceCapabilityArray
  );

  number = cleanPhoneNumber(number);

  // Whenever we get caps for a number, we invalidate our internal cache for it
  await invalidateCapsCache(number);

  defaultStore.set(atoms.capabilities.capabilities, {
    ...(defaultStore.get(atoms.capabilities.capabilities) || {}),
    [number]: serviceCapabilityArray || [],
  });
}
