import { ComponentType } from "react";
import {
  createHashRouter,
  generatePath,
  Navigate,
  redirect,
  useNavigate,
} from "react-router-dom";
import RouterErrorHandler from "./RouterErrorHandler";
import { defaultPage, paths } from "./routerPaths";
import { getForcedSignedOut } from "./utils/helpers/localstorage";
import {
  isProvisioned,
  wasPreprovisioned,
} from "./utils/helpers/provisionRequest";
import {
  isEmbeddedMode,
  PARAM_NAME_ORGANIZATION_ID,
} from "./utils/hooks/useOdienceOrganization";

/**
 * preloads pages (browser caches these imports)
 */
async function preloadPages() {
  // first ones imported are prioritized
  await import("./pages/provisioning");
  await Promise.allSettled([
    import("./pages/recentCallScreen/RecentCallScreen"),
    import("./pages/conversationsScreen/ConversationsScreen"),
  ]);
  await Promise.allSettled([
    import("./pages/contactListScreen/ContactListScreenWrapper"),
    import("./pages/chatbotScreen/ChatbotScreen"),
  ]);
  await Promise.allSettled([
    import("./components/odience/pages/eventList/EventListPage"),
    import("./components/odience/pages/eventDetails/EventDetailsPage"),
    import("./components/odience/pages/eventStream/EventStreamPage"),
  ]);
  await import("./pages/settingsScreen/SettingsScreen");
}

const previewOdienceStreamComponent = async () => {
  const { default: EventStreamPage } = await import(
    "./components/odience/pages/eventStream/EventStreamPage"
  );
  return {
    Component: () => <EventStreamPage />,
  };
};

const EventListComponent = async (): Promise<{
  Component: ComponentType<any>;
}> => {
  const EventListComponent = await import(
    "./components/odience/pages/eventList/EventListPage"
  );
  return {
    Component: EventListComponent.default,
  };
};

export const router = createHashRouter([
  {
    path: paths.any,
    element: <Navigate to={defaultPage} />,
  },
  {
    path: paths.eula,
    // element: <Eula />,
    errorElement: <RouterErrorHandler />,
    lazy: async () => ({
      Component: (await import("./pages/EULA")).default,
    }),
  },
  {
    path: paths.privacyPolicy,
    // element: <Eula />,
    errorElement: <RouterErrorHandler />,
    lazy: async () => ({
      Component: (await import("./pages/PrivacyPolicy")).default,
    }),
  },
  {
    path: paths.onboarding,
    // element: <Onboarding />,
    errorElement: <RouterErrorHandler />,
    loader: () => {
      if (isProvisioned()) {
        return redirect(defaultPage);
      }
      if (wasPreprovisioned() || getForcedSignedOut()) {
        return redirect(paths.provisioning);
      }
      return null;
    },
    lazy: async () => ({
      Component: (await import("./pages/onboardingScreen/OnboardingScreen"))
        .default,
    }),
  },
  {
    path: paths.previewOdience,
    errorElement: <RouterErrorHandler />,
    loader: ({ request }) => {
      if (isProvisioned()) {
        // In case user is already provisioned we make sure to not lose the organization parameter passed in the preview link
        const organizationId = new URL(request.url).searchParams.get(
          PARAM_NAME_ORGANIZATION_ID
        );

        if (organizationId) {
          return redirect(
            `${paths.odience}?${PARAM_NAME_ORGANIZATION_ID}=${organizationId}`
          );
        } else {
          return redirect(paths.odience);
        }
      }

      // This flag can only exist once after being kicked out from the server, which redirects either to onboarding path (if using Verse) or previewOdience path (when using odience only)
      if (getForcedSignedOut()) {
        return redirect(paths.provisioningFromOdience);
      }

      import("./components/odience/pages/eventList/EventListPage");
      return null;
    },
    lazy: EventListComponent,
  },
  {
    path: paths.previewOdienceDetails,
    errorElement: <RouterErrorHandler />,
    loader: ({ params, request }) => {
      if (isProvisioned()) {
        const searchParams = new URLSearchParams(request.url.split("?")[1]);

        const newPath = generatePath(paths.details, {
          groupId: params["groupId"]!,
          eventId: params["eventId"]!,
        });
        const searchString = searchParams.toString()
          ? `?${searchParams.toString()}`
          : "";
        return redirect(`${newPath}${searchString}`);
      }
      return null;
    },
    lazy: async () => ({
      Component: (
        await import("./components/odience/pages/eventDetails/EventDetailsPage")
      ).default,
    }),
  },

  {
    path: paths.previewOdienceStream,
    errorElement: <RouterErrorHandler />,
    loader: ({ params }) => {
      if (isProvisioned()) {
        return redirect(
          generatePath(paths.stream, {
            eventId: params["eventId"]!,
          })
        );
      }
      return null;
    },
    lazy: previewOdienceStreamComponent,
  },
  {
    path: paths.provisioning,
    // element: <Provisioning />,
    errorElement: <RouterErrorHandler />,
    lazy: async () => ({
      Component: (await import("./pages/provisioning")).default,
    }),
  },
  {
    path: paths.root,
    errorElement: <RouterErrorHandler />,
    loader: ({ request }) => {
      if (!isProvisioned()) {
        const path = new URL(request.url).pathname.replaceAll("/", "");
        switch (path) {
          case paths.odience.replaceAll("/", ""):
          case paths.previewOdience.replaceAll("/", ""):
            return redirect(paths.previewOdience);
          default:
            if (isEmbeddedMode()) {
              return redirect(paths.previewOdience);
            }
            return redirect(paths.onboarding);
        }
      }

      setTimeout(preloadPages, 1000);

      const url = new URL(request.url);
      if (url.pathname === paths.root) {
        return redirect(defaultPage);
      }

      return null;
    },
    // element: <Layout />,
    lazy: async () => ({
      Component: (await import("./pages/layout/Layout")).default,
    }),
    children: [
      {
        path: paths.calls,
        // element: <RecentCallScreen />,
        errorElement: <RouterErrorHandler />,
        lazy: async () => ({
          Component: (await import("./pages/recentCallScreen/RecentCallScreen"))
            .default,
        }),
      },
      {
        path: paths.messages,
        // element: <ConversationsScreen />,
        errorElement: <RouterErrorHandler />,
        lazy: async () => ({
          Component: (
            await import("./pages/conversationsScreen/ConversationsScreen")
          ).default,
        }),
      },
      {
        path: paths.contacts,
        // element: <ContactListScreenWrapper />,
        errorElement: <RouterErrorHandler />,
        lazy: async () => ({
          Component: (
            await import("./pages/contactListScreen/ContactListScreenWrapper")
          ).default,
        }),
      },
      {
        path: paths.chatbots,
        // element: <ChatbotScreen />,
        errorElement: <RouterErrorHandler />,
        lazy: async () => ({
          Component: (await import("./pages/chatbotScreen/ChatbotScreen"))
            .default,
        }),
      },
      {
        path: paths.odience,
        // element: <OdienceScreen />,
        errorElement: <RouterErrorHandler />,
        lazy: EventListComponent,
      },
      {
        path: paths.details,
        errorElement: <RouterErrorHandler />,
        lazy: async () => {
          if (!isProvisioned()) {
            const navigate = useNavigate();
            navigate(paths.previewOdienceDetails);
          }

          return {
            Component: (
              await import(
                "./components/odience/pages/eventDetails/EventDetailsPage"
              )
            ).default,
          };
        },
      },
      {
        path: paths.stream,
        // element: <EventStream />,
        errorElement: <RouterErrorHandler />,
        lazy: async () => ({
          Component: (
            await import(
              "./components/odience/pages/eventStream/EventStreamPage"
            )
          ).default,
        }),
      },
      {
        path: paths.settings,
        // element: <SettingsScreen />,
        errorElement: <RouterErrorHandler />,
        lazy: async () => ({
          Component: (await import("./pages/settingsScreen/SettingsScreen"))
            .default,
        }),
      },
    ],
  },
]);
