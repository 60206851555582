import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 1000 * 60 * 2,
      retry: 2,
      retryDelay: (attemptCount) => Math.min(1000 * 2 ** attemptCount, 60000),
    },
  },
});
