import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { DEFAULT_COUNTRY } from "../constants/common";
import { fetchAndStoreUserLocationCountryCode } from "../map";

export const queryUserLocationCountry = {
  staleTime: Infinity,
  queryKey: ["user-location-country"],
  queryFn: async () => {
    try {
      const country = await fetchAndStoreUserLocationCountryCode();
      country.country_code = `+${country.country_code}`;
      return country;
    } catch (e) {
      console.error(e);

      // Default North America
      return DEFAULT_COUNTRY;
    }
  },
} as const satisfies ReturnType<Parameters<typeof atomWithSuspenseQuery>[0]>;
