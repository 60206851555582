import { Config } from "@/types/common";
import { getDefaultStore } from "jotai";
import { queryConfig, queryConfigStaleTimeMs } from "../queries/config";
import { queryClient } from "../queryClient";
import { atoms } from "./atoms";

const defaultStore = getDefaultStore();

export async function getConfig(forceUpdate = false) {
  const configQuery = await defaultStore.get(atoms.common.config);

  /**
   * If the atom does not return anything but marked as success in the query client (coming out from sleep, no internet connection),
   * atom.data will be null. At this point, even a refetch (from below) or invalidating the query wont update it, but query client will have the updated data.
   * Let's make sure we read the data from both places
   */
  let config =
    configQuery.data ||
    queryClient.getQueryData<Awaited<ReturnType<typeof queryConfig.queryFn>>>(
      queryConfig.queryKey
    ) ||
    null;

  if (!config || forceUpdate) {
    console.log(`Config is empty or forcing update (${forceUpdate})`);

    // For some reasons invalidating the query directly does not work, hence forcing here a refetch that will update the data in the query client
    config = (await configQuery.refetch()).data || null;
  }

  return config;
}

export async function checkAndRefetchConfig(
  forceUpdate = false
): Promise<Config | null> {
  // Check if configAtom is empty or stale
  let config = await getConfig();
  const lastRefreshTimestamp = localStorage.getItem("lastRefreshTimestamp");
  const staled =
    lastRefreshTimestamp &&
    Date.now() - parseInt(lastRefreshTimestamp, 10) >= queryConfigStaleTimeMs;

  if (!config || staled || forceUpdate) {
    console.log(`Config is empty or data is staled (${staled})`);
    // If configAtom is empty or stale, initiate the refetch
    config = await getConfig(true);
  }

  return config;
}
