import { Suspense, lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ThreeLinesLoaderBox } from "./ThreeLines";

function loadReconnectingAnimationLottie() {
  return import("./ReconnectingAnimationLottie");
}
const ReconnectingAnimationLottie = lazy(loadReconnectingAnimationLottie);

// preload
loadReconnectingAnimationLottie();

const AnimationComponent = ({ children }) => {
  return (
    <div css={{ height: "5vh", width: "auto", aspectRatio: 650 / 670 }}>
      <ErrorBoundary fallback={<ThreeLinesLoaderBox />}>
        <Suspense fallback={<ThreeLinesLoaderBox />}>{children}</Suspense>
      </ErrorBoundary>
    </div>
  );
};

export const ReconnectingAnimation = () => {
  return (
    <AnimationComponent>
      <ReconnectingAnimationLottie />
    </AnimationComponent>
  );
};

function loadWeakConnectionAnimation() {
  return import("./WeakConnectionAnimationLottie");
}

const WeakConnectionAnimationLottie = lazy(loadWeakConnectionAnimation);

export const WeakConnectionAnimation = () => {
  return (
    <AnimationComponent>
      <WeakConnectionAnimationLottie />
    </AnimationComponent>
  );
};
