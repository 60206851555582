import {
  getLocalOrganizationId,
  removeLocalOrganizationId,
  setLocalOrganizationId,
} from "@/utils/helpers/localstorage";
import { useSearchParams } from "react-router-dom";

export const PARAM_NAME_ORGANIZATION_ID = "organizationId";

export function useOdienceOrganization() {
  const [searchParams, _] = useSearchParams();
  const organizationIdParam = searchParams.get(PARAM_NAME_ORGANIZATION_ID);

  if (organizationIdParam && parseInt(organizationIdParam)) {
    console.log(
      `Odience organization pre-set param with id ${organizationIdParam}`
    );
    setLocalOrganizationId(organizationIdParam);
  } else {
    removeLocalOrganizationId();
  }

  const organizationId = getLocalOrganizationId();

  return {
    organizationId,
    embeddedMode: isEmbeddedMode(),
  };
}

// Embedded mode is valid only if the window is being viewed through an iframe
export function isEmbeddedMode() {
  return window.self !== window.top;
}
