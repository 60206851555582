import VCard from "vcard-creator";
import { generateRandomString } from "../helpers/Utils";
import { contactState } from "./contactState";

export default class Contact {
  contactId: any;
  private phone: any;
  private contactFirstName: any;
  private contactLastName: any;
  private company?: any;
  private email?: any;
  private website?: any;
  private address?: any;
  private birthday?: any;
  private note?: any;
  private related?: any;
  private photo?: any;

  constructor(contact: typeof contactState) {
    this.contactId = generateRandomString(16);
    this.phone = contact.phoneNumber;
    this.contactFirstName = contact.firstName;
    this.contactLastName = contact.lastName;
    this.company = contact.company;
    this.email = contact.email;
    this.website = contact.website;
    this.address = contact.address;
    this.birthday = contact.birthday;
    this.note = contact.notes;
    this.related = contact.related;
    this.photo = contact.userAvatar;
  }

  body() {
    const vcard = new VCard();

    const attributes: any[] = [];
    for (let i = 0; i < this.phone?.length; i++) {
      let number = this.phone[i].number;
      let phoneType = this.phone[i].type;

      vcard.addPhoneNumber(number, phoneType);
    }

    vcard.addName(this.contactLastName, this.contactFirstName);
    vcard.addCompany(this.company);

    for (let i = 0; i < this.email?.length; i++) {
      let email = this.email[i]?.email;
      let emailType = this.email[i]?.type;

      vcard.addEmail(email, emailType);
    }

    vcard.addURL(this.website);

    for (let i = 0; i < this.address?.length; i++) {
      let addressType = this.address[i].type;
      let street1 = this.address[i].street1;
      let street2 = this.address[i].street2;
      let city = this.address[i].city;
      let province = this.address[i].province;
      let postalCode = this.address[i].postalCode;
      let country = this.address[i].country;

      vcard.addAddress(
        addressType,
        street1,
        street2,
        city,
        province,
        postalCode,
        country
      );
    }

    vcard.addBirthday(this.birthday);
    vcard.addNote(this.note);
    vcard.addPhoto(this.photo);

    attributes.push({
      name: "name",
      value: this.contactFirstName + " " + this.contactLastName,
    });

    attributes.push({
      name: "company",
      value: this.company,
    });

    attributes.push({
      name: "url",
      value: this.website,
    });

    attributes.push({
      name: "birthday",
      value: this.birthday,
    });

    attributes.push({
      name: "note",
      value: this.note,
    });

    for (let i = 0; i < this.related?.length; i++) {
      if (`person${this.related[i].type}` && this.related[i].name === "") {
        attributes[`person${this.related[i].type}`] = {
          name: `person${this.related[i].type}`,
          value: [this.related[i].name, this.related[i].id],
        };
      } else {
        attributes.push({
          name: `person${this.related[i].type}`,
          value: [this.related[i].name, this.related[i].id],
        });
      }
    }

    for (let i = 0; i < this.phone?.length; i++) {
      if (`phone${this.phone[i].type}` && this.phone[i].number === "") {
        attributes[`phone${this.phone[i].type}`] = {
          name: `phone${this.phone[i].type}`,
          value: [this.phone[i].number, this.phone[i].id, this.phone[i].caps],
        };
      } else {
        attributes.push({
          name: `phone${this.phone[i].type}`,
          value: [this.phone[i].number, this.phone[i].id, this.phone[i].caps],
        });
      }
    }

    for (let i = 0; i < this.email?.length; i++) {
      if (`email${this.email[i].type}` && this.email[i].email === "") {
        attributes[`email${this.email[i].type}`] = {
          name: `email${this.email[i].type}`,
          value: [this.email[i].email, this.email[i].id],
        };
      } else {
        attributes.push({
          name: `email${this.email[i].type}`,
          value: [this.email[i].email, this.email[i].id],
        });
      }
    }

    for (let i = 0; i < this.address?.length; i++) {
      let addressType = this.address[i].type;
      let street1 = this.address[i].street1;
      let street2 = this.address[i].street2;
      let city = this.address[i].city;
      let province = this.address[i].province;
      let postalCode = this.address[i].postalCode;
      let country = this.address[i].country;
      let id = this.address[i].id;

      if (`address${this.address[i].type}` && this.address[i].street1 === "") {
        attributes[`address${this.address[i].type}`] = {
          name: `address${this.address[i].type}`,
          value: [
            addressType,
            street1,
            street2,
            city,
            province,
            postalCode,
            country,
            id,
          ],
        };
      } else {
        attributes.push({
          name: `address${this.address[i].type}`,
          value: [
            addressType,
            street1,
            street2,
            city,
            province,
            postalCode,
            country,
            id,
          ],
        });
      }
    }

    return JSON.stringify({
      contact: {
        contactId: this.contactId,
        attributeList: {
          attribute: attributes,
        },
      },
    });
  }
}
