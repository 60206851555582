import { getWebRTC } from "../../utils/webrtc/webrtcUtils";
import { getBrowserName } from "./getBrowser";

const LOG_PREFIX = "mediaStream: ";

export const AUDIO_CONSTRAINT = {
  audio: true,
};

export const LEGACY_BROWSER_VIDEO_CONSTRAINTS = {
  audio: true,
  video: { width: { exact: 1280 }, height: { exact: 720 } },
};
export const VIDEO_CALL_CONSTRAINTS = {
  audio: true,
  video: {
    advanced: [
      {
        width: { exact: 1280 },
        height: { exact: 720 },
        frameRate: { exact: 30 },
      },
    ],
  },
};

export const VIDEO_CALL_IDEAL_CONSTRAINTS = {
  audio: true,
  video: {
    advanced: [
      {
        width: { ideal: 1280 },
        height: { ideal: 720 },
        frameRate: { ideal: 30 },
      },
    ],
  },
};

let _audioStream: MediaStream | undefined;
export const getAudioStream = async () => {
  console.log(LOG_PREFIX, "getAudioStream");

  // Only audio, always release first in case previous set
  await releaseVideoCallStream();
  await releaseAudioStream();

  _audioStream = await navigator.mediaDevices
    .getUserMedia(AUDIO_CONSTRAINT)
    .catch((error) => {
      console.error(error);
      return undefined;
    });

  if (!_audioStream) {
    console.error(LOG_PREFIX, "Couldn't acquire audio stream");
    return;
  } else {
    console.log(LOG_PREFIX, "Acquired audio stream: ", _audioStream);
  }

  return _audioStream;
};

export const releaseAudioStream = async () => {
  console.log(LOG_PREFIX, "releaseAudioStream");
  try {
    _audioStream?.getTracks().forEach((track) => track.stop());
    _audioStream = undefined;
  } catch (e) {
    console.error(LOG_PREFIX, "Error releasing audio stream: ", e);
  }
};

let _videoCallStream: MediaStream | undefined;
export const getVideoCallStream = async (
  remoteVideoRef: any,
  outgoingVideoRef: any
) => {
  // Always release first in case previous set
  await releaseVideoCallStream();

  console.log(LOG_PREFIX, "getVideoCallStream");
  const globalWebRTC = getWebRTC();
  if (remoteVideoRef && globalWebRTC) {
    globalWebRTC.setVideoRef(remoteVideoRef);
  }

  const vcc = !navigator.permissions
    ? LEGACY_BROWSER_VIDEO_CONSTRAINTS
    : getBrowserName(navigator.userAgent) !== "Firefox"
      ? VIDEO_CALL_CONSTRAINTS
      : VIDEO_CALL_IDEAL_CONSTRAINTS;
  _videoCallStream = await navigator.mediaDevices
    .getUserMedia(vcc)
    .catch((error) => {
      console.error(error);
      return undefined;
    });

  if (!_videoCallStream) {
    console.error(LOG_PREFIX, "Couldn't acquire video stream");
    return undefined;
  } else {
    console.log(LOG_PREFIX, "Acquired video stream: ", _videoCallStream);
  }

  if (outgoingVideoRef && outgoingVideoRef.current) {
    outgoingVideoRef.current.srcObject = _videoCallStream;
  } else {
    console.error("Ref for outgoing video view is undefined");
  }

  return _videoCallStream;
};

export const releaseVideoCallStream = async () => {
  console.log(LOG_PREFIX, "releaseVideoCallStream");
  try {
    _videoCallStream?.getTracks().forEach((track) => track.stop());
    _videoCallStream = undefined;

    const globalWebRTC = getWebRTC();
    if (globalWebRTC) {
      globalWebRTC.setAudioRef(undefined);
      globalWebRTC.setVideoRef(undefined);
    }
  } catch (e) {
    console.error(LOG_PREFIX, "Error releasing video call stream: ", e);
  }
};
