type LocalHardwareStatus = {
  AVAILABLE: true;
  NO_MIC: "No Mic";
  NO_CAMERA: "No Camera";
};

export const LocalHardwareStatusValues: LocalHardwareStatus = {
  AVAILABLE: true,
  NO_MIC: "No Mic",
  NO_CAMERA: "No Camera",
};

export const checkAvailableHardware = async () => {
  if (!navigator.permissions) return LocalHardwareStatusValues.AVAILABLE;
  const devices = await navigator.mediaDevices.enumerateDevices();
  const camera = devices.some((device) => device.kind === "videoinput");
  const microphone = devices.some((device) => device.kind === "audioinput");

  if (camera && microphone) {
    return LocalHardwareStatusValues.AVAILABLE;
  }
  if (!camera) {
    return LocalHardwareStatusValues.NO_CAMERA;
  }
  if (!microphone) {
    return LocalHardwareStatusValues.NO_MIC;
  }
};
