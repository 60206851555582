import tinycolor from "tinycolor2";

export const convertToRgba = (value: string, opacity: number): string => {
  return tinycolor(value).setAlpha(opacity).toRgbString();
};

export const convertToHex = (value: string): string => {
  return tinycolor(value).toHexString();
};

export const convertToHex8 = (value: string): string => {
  return tinycolor(value).toHex8String();
};
