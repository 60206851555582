import toWav from "audiobuffer-to-wav";
import toLower from "lodash/toLower";

enum TranscriptionLineType {
  TEXT,
  SILENCE,
  BLANK,
  PAUSE,
  SOUND,
  INAUDIBLE,
  TYPING_SOUNDS,
  MUSIC,
}

type AudioTranscript = {
  speakerId?: number; //0: local, 1: remote
  type?: TranscriptionLineType;
  text?: string; // Only set when type is text
};

type Transcript = {
  transcriptLines?: AudioTranscript[] | undefined;
  elapsedStart?: number; //Based on the call duration, starting at 0
  elapsedEnd?: number | undefined; //Based on the call duration
};

interface IVoiceBot {
  microphoneAudioStream?: MediaStream | undefined;
  incomingAudioStream?: MediaStream | undefined;
  type: string;
  callId: string;
}

export enum VOICE_BOT_STATE {
  recordingStart,
  recordingStop,
  recordingLiveTranscript,
  recordingCallTranscriptDone,
}

export const baseVoiceBotUrl = window._env_.VOICE_BOT_URL;
export const voiceBotAuthorizationToken =
  window._env_.VOICE_BOT_AUTHORIZATION_TOKEN;

export default class VoiceBot {
  private static LIVE = "live";
  private static CALL = "call";

  private TAG!: string;
  private liveMediaRecorder: MediaRecorder | undefined;
  private incomingMediaRecorder: MediaRecorder | undefined;
  private microphoneMediaRecorder: MediaRecorder | undefined;
  private liveRecordingInterval: NodeJS.Timeout | undefined;
  private liveAudioChunks: any = [];
  private microphoneAudioBuffer: AudioBuffer | undefined;
  private incomingAudioBuffer: AudioBuffer | undefined;
  private detectedSilence: boolean = false;
  private incomingStream: MediaStream | undefined;
  private microphoneStream: MediaStream | undefined;
  private audioBotLiveActive: boolean = false;
  private callSummary?: string | undefined = undefined;
  private transcripts: Transcript[] = [];
  private callStartTime: number;
  private callId: string;

  // callbacks
  private onTranscriptCallback!: any;
  private onVoiceBotStateChange!: any;

  constructor(obj: IVoiceBot) {
    this.incomingStream = obj.incomingAudioStream;
    this.microphoneStream = obj.microphoneAudioStream;
    this.TAG = obj.type;
    this.callId = obj.callId;

    this.callStartTime = Date.now();

    console.log(this.TAG, "creating new Voicebot, callId=", this.callId);
  }

  public setTranscriptCallback(transcriptCallback: any) {
    this.onTranscriptCallback = transcriptCallback;
  }

  /**
   * This is called just before sending the transcription to NMS. This is the format saved inside the payloadPart of the
   * NmsObject.
   * @returns A JSON format of the transcriptions of the call.
   */
  public getFormattedTranscript() {
    return {
      "content-type": "application/vnd.voicebot+json",
      content: {
        callSummary: this.callSummary,
        transcript: this.transcripts,
      },
    };
  }

  public isTranscriptValid() {
    console.log(
      "transcript lines = ",
      this.transcripts.length,
      ", callSummary = ",
      this.callSummary
    );
    return (
      this.transcripts.length > 0 &&
      this.transcripts[this.transcripts.length - 1].elapsedEnd &&
      this.transcripts[this.transcripts.length - 1].transcriptLines?.length &&
      this.callSummary
    );
  }

  public clearVoicebot() {
    this.transcripts = [];
    this.liveMediaRecorder = undefined;
    this.incomingMediaRecorder = undefined;
    this.microphoneMediaRecorder = undefined;
    this.liveRecordingInterval = undefined;
    this.liveAudioChunks = [];
    this.microphoneAudioBuffer = undefined;
    this.incomingAudioBuffer = undefined;
    this.detectedSilence = false;
    this.incomingStream = undefined;
    this.microphoneStream = undefined;
    this.audioBotLiveActive = false;
    this.callSummary = "";
    this.callStartTime = 0;
  }

  public setOnVoiceBotStateChange(pOnCallTranscriptStateChange: any) {
    this.onVoiceBotStateChange = pOnCallTranscriptStateChange;
  }

  /**
   * This takes the OPUS 48Khz format that WebRTC uses and resamples it to 16kHz wav format that is required by AudioBot.
   * @param audioBuffer The OPUS decoded AudioStream to be resampled.
   * @param targetSampleRate The sampleRate which AudioBot requires (16kHz).
   * @returns The resampled audio stream.
   */
  private async resampleAudioBuffer(
    audioBuffer: AudioBuffer,
    targetSampleRate: number
  ): Promise<AudioBuffer> {
    const length =
      (audioBuffer.length * targetSampleRate) / audioBuffer.sampleRate;
    const offlineCtx = new OfflineAudioContext(
      audioBuffer.numberOfChannels,
      length,
      targetSampleRate
    );

    // Create a buffer source
    const bufferSource = offlineCtx.createBufferSource();
    bufferSource.buffer = audioBuffer;

    // Connect the source to the context
    bufferSource.connect(offlineCtx.destination);
    bufferSource.start(0);

    // Render the audio
    return await offlineCtx.startRendering(); // The rendered audio will be at 16 kHz
  }

  /**
   * Handles the transcription live, which means everytime we detect the end of a sentence (a pause).
   * @param text The current sentence in text. (transcribed)
   */
  private handleLiveTranscribe(text: string | undefined) {
    if (text && text !== "") {
      if (this.onTranscriptCallback) {
        this.onTranscriptCallback(text);
      }
    }
  }

  /**
   * Handle the transcription for a Call (non-live) scenario. This happens when the user stops AudioBot or ends the call.
   * @param text The final transcription of the session.
   */
  private handleCallTranscribe(text: string) {
    for (let i = 0; i < this.transcripts.length; i++) {
      if (this.transcripts[i].transcriptLines === undefined) {
        const transcripts: AudioTranscript[] = this.parseTranscript(text);
        this.transcripts[i].transcriptLines = transcripts;
        break;
      }
    }

    this.getCallSummary(this.prepareTranscriptForSummary())
      .then((callSummaryResponse) => {
        return callSummaryResponse.json();
      })
      .then((callSummaryJson) => {
        console.log(this.TAG, "Recorder: Question's response", callSummaryJson);
        this.callSummary = callSummaryJson.response;
      })
      .finally(() => {
        if (this.onVoiceBotStateChange) {
          this.onVoiceBotStateChange(
            this.callId,
            VOICE_BOT_STATE.recordingCallTranscriptDone
          );
        }
      });
  }

  /**
   * Decode the RTP stream received into a readable audio stream.
   * @param blob The RTP stream.
   * @returns A decoded AudioStream
   */
  public async decodeAudioDataForWAVConversation(
    blob: Blob
  ): Promise<AudioBuffer> {
    const audioCtx = new AudioContext();
    return blob.arrayBuffer().then((arrayBuffer: ArrayBuffer) => {
      return audioCtx.decodeAudioData(arrayBuffer);
    });
  }

  /**
   * This takes care of calling the resampling function, converting it to WAV (raw) and then calling AudioBot
   * with the result to transcribe it.
   * @param audioBuffer The OPUS decoded AudioBuffer
   */
  private ResampleAndTranscribe(audioBuffer: AudioBuffer) {
    this.resampleAudioBuffer(audioBuffer, 16000)
      .then((resampledAudioBuffer: AudioBuffer) => {
        const wavBuffer = toWav(resampledAudioBuffer);
        const wavBlob = new Blob([wavBuffer], { type: "audio/wav" });
        return this.transcribe(wavBlob, this.TAG === VoiceBot.LIVE);
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to transcribe");
        }
        return response.json();
      })
      .then((json) => {
        console.log(this.TAG, "Recorder: transcription done -> ", json.text);
        if (this.TAG === VoiceBot.LIVE && this.audioBotLiveActive) {
          this.handleLiveTranscribe(json.text);
        } else if (this.TAG === VoiceBot.CALL) {
          this.handleCallTranscribe(json.text);
        }
      })
      .catch((error) => {
        console.error(
          this.TAG,
          "Recorder: Caught error while transcribing:",
          error
        );
      });
  }

  /**
   * Parse the payload sent by AudioBot and create our JSON structure internally to be sent to NMS later.
   * @param text The payload coming form AudioBot.
   * @returns The JSON format required for NMS.
   */
  private parseTranscript(text: string): AudioTranscript[] {
    const lines = text.split("\n");
    const audioTranscripts: AudioTranscript[] = [];

    lines.forEach((line) => {
      console.log(this.TAG, "Recorder: parsing line :", line);
      const speakerMatch = line.match(/\(speaker (\d)\)/);
      const bracketMatch = line.match(/\[(.*?)\]/);
      const audioTranscript: AudioTranscript = {};

      if (speakerMatch) {
        audioTranscript.speakerId = parseInt(speakerMatch[1]);
      }

      if (bracketMatch) {
        switch (toLower(bracketMatch[1])) {
          case "blank_audio":
            audioTranscript.type = TranscriptionLineType.BLANK;
            break;
          case "silence":
            audioTranscript.type = TranscriptionLineType.SILENCE;
            break;
          case "pause":
            audioTranscript.type = TranscriptionLineType.PAUSE;
            break;
          case "sound":
            audioTranscript.type = TranscriptionLineType.SOUND;
            break;
          case "inaudible":
            audioTranscript.type = TranscriptionLineType.INAUDIBLE;
            break;
          case "typing":
          case "typing sounds":
            audioTranscript.type = TranscriptionLineType.TYPING_SOUNDS;
            break;
          case "music":
            audioTranscript.type = TranscriptionLineType.MUSIC;
            break;
          default:
            audioTranscript.type = TranscriptionLineType.BLANK;
            break;
        }
      } else {
        audioTranscript.type = TranscriptionLineType.TEXT;
        audioTranscript.text = speakerMatch
          ? line.replace(/\(speaker \d\)\s*/, "")
          : line;
      }

      if (
        audioTranscript.text === "" &&
        audioTranscript.type === TranscriptionLineType.TEXT
      ) {
        // ignore
      } else {
        audioTranscripts.push(audioTranscript);
      }
    });

    return audioTranscripts;
  }

  /**
   * Take our json structure of the transcriptions and make a nice text with Me: and Remote: as prefix.
   * @returns The whole text required by the AudioBot to summarize it.
   */
  private prepareTranscriptForSummary() {
    return this.transcripts
      .map((it) =>
        it.transcriptLines
          ?.filter((line) => line.type === TranscriptionLineType.TEXT)
          .map((line) =>
            line.speakerId === 0 ? `Me: ${line.text}` : `Remote: ${line.text}`
          )
          .join("\n")
      )
      .join("\n");
  }

  /**
   * This is used in live transcription to identify the end of a sentence. This is also when we decide to send a POST
   * to transcribe the current accumulated audio stream.
   * @param audioBuffer the accumulated Audio stream.
   * @param threshold the amount of sound that counts as silence.
   * @param minSilenceDuration the duration of the silence that counts as end of sentence.
   * @param sampleRate the samplerate of the audio we are analyzing. (16khz)
   * @returns true if a pause in the audio was detected.
   */
  private detectSilence(
    audioBuffer: AudioBuffer,
    threshold: number,
    minSilenceDuration: number,
    sampleRate: number
  ): boolean {
    const channelData = audioBuffer.getChannelData(0); // Get data from the first channel
    const samplesPerChunk = Math.floor(minSilenceDuration * sampleRate);
    let silentSegments: any = [];
    let isSilent = false;
    let silenceStart = 0;

    for (let i = 0; i < channelData.length; i++) {
      if (Math.abs(channelData[i]) < threshold) {
        if (!isSilent) {
          isSilent = true;
          silenceStart = i;
        }
      } else {
        if (isSilent) {
          let silenceEnd = i;
          let silenceDuration = (silenceEnd - silenceStart) / sampleRate;
          if (silenceEnd - silenceStart >= samplesPerChunk) {
            silentSegments.push({
              start: silenceStart / sampleRate,
              end: silenceEnd / sampleRate,
              duration: silenceDuration,
            });
          }
          isSilent = false;
        }
      }
    }

    return isSilent && channelData.length - silenceStart >= samplesPerChunk;
  }

  private restartLiveRecording() {
    this.liveAudioChunks = [];
    if (this.audioBotLiveActive) {
      this.startLiveRecording();
    }
  }

  /**
   * Callback when recording from microphone. This is used for the total call transcription, not live.
   * @param ev The blob event containing the AudioBuffer
   */
  private onCallMicrophoneRecorderDataAvailable(ev: BlobEvent) {
    console.log(
      this.TAG,
      "Recorder: received final data for microphone audio buffer"
    );
    if (this.microphoneMediaRecorder?.state === "inactive") {
      this.decodeAudioDataForWAVConversation(ev.data).then(
        (audioBuffer: AudioBuffer) => {
          console.log(this.TAG, "Recorder: decoded microphone audio buffer");
          this.microphoneAudioBuffer = audioBuffer;
          const combinedAudioBuffer = this.combineToStereo();
          if (combinedAudioBuffer) {
            return this.ResampleAndTranscribe(combinedAudioBuffer);
          }
        }
      );
    }
  }

  /**
   * Callback when recording from the incoming audio source. This is used for the total call transcription, not live.
   * @param ev The blob event containing the AudioBuffer
   */
  private onCallIncomingRecorderDataAvailable(ev: BlobEvent) {
    console.log(
      this.TAG,
      "Recorder: received final data for incoming audio buffer"
    );
    if (this.incomingMediaRecorder?.state === "inactive") {
      this.decodeAudioDataForWAVConversation(ev.data).then(
        (audioBuffer: AudioBuffer) => {
          console.log(this.TAG, "Recorder: decoded incoming audio buffer");
          this.incomingAudioBuffer = audioBuffer;
          const combinedAudioBuffer = this.combineToStereo();
          if (combinedAudioBuffer) {
            return this.ResampleAndTranscribe(combinedAudioBuffer);
          }
        }
      );
    }
  }

  /**
   * Since our Audio Bot server can use the left channel and right channel for local and remote audio, we combine both
   * so it will correctly place the silence and sentences in the transcription.
   * @returns A single AudioBuffer that contains both Microphone and Incoming audio stream.
   */
  private combineToStereo() {
    console.log(
      this.TAG,
      `Recorder: incomingAudioBuffer=${this.incomingAudioBuffer} && microphoneAudioBuffer=${this.microphoneAudioBuffer}`
    );
    if (this.incomingAudioBuffer && this.microphoneAudioBuffer) {
      console.log(
        this.TAG,
        "Recorder: both audioStream are ready to be combined"
      );
      const audioCtx = new AudioContext();
      const numberOfChannels = 2;
      const length = this.incomingAudioBuffer.length;
      const sampleRate = this.incomingAudioBuffer.sampleRate;

      const stereoBuffer = audioCtx.createBuffer(
        numberOfChannels,
        length,
        sampleRate
      );

      const trimmedMicrophoneBuffer = audioCtx.createBuffer(
        1,
        length,
        sampleRate
      );
      const originalData = this.microphoneAudioBuffer.getChannelData(0);
      const trimmedData = trimmedMicrophoneBuffer.getChannelData(0);
      trimmedData.set(
        originalData.subarray(
          this.microphoneAudioBuffer.length - length,
          length
        )
      );

      stereoBuffer.getChannelData(0).set(trimmedData);
      stereoBuffer
        .getChannelData(1)
        .set(this.incomingAudioBuffer.getChannelData(0));

      this.incomingAudioBuffer = undefined;
      this.microphoneAudioBuffer = undefined;
      return stereoBuffer;
    }
  }

  /**
   * Callback when recording from the incoming audio source. This is used for live scenario where we display the
   * sentence each time we identify a silence of 0.5 seconds (configurable).
   * @param ev The blob event containing the AudioBuffer
   */
  private onLiveRecorderDataAvailable(ev: BlobEvent) {
    if (!this.audioBotLiveActive) {
      return;
    }

    if (this.liveMediaRecorder?.state === "inactive") {
      console.log(this.TAG, "Recorder: done recording, converting to wav...");
      this.liveAudioChunks.push(ev.data);
      const combinedBlob = new Blob(this.liveAudioChunks, {
        type: "audio/webm;codecs=opus",
      });
      this.decodeAudioDataForWAVConversation(combinedBlob)
        .then((audioBuffer) => {
          this.ResampleAndTranscribe(audioBuffer);
        })
        .finally(() => {
          console.info(this.TAG, "Recorder: clearing accumulated audio chunks");
          this.restartLiveRecording();
        })
        .catch((error) =>
          console.error(
            this.TAG,
            "Recorder: failed to convert to WAV with error:",
            error
          )
        );
    } else {
      console.log(
        this.TAG,
        "Recorder: requesting data, analyzing for silence..."
      );
      this.liveAudioChunks.push(ev.data);
      const combinedBlob = new Blob(this.liveAudioChunks, {
        type: "audio/webm;codecs=opus",
      });
      this.decodeAudioDataForWAVConversation(combinedBlob)
        .then((audioBuffer: AudioBuffer) => {
          if (this.detectSilence(audioBuffer, 0.01, 0.25, 16000)) {
            if (!this.detectedSilence) {
              console.log(
                this.TAG,
                "Recorder: detected new silence, transcribing!!!!"
              );
              if (this.liveRecordingInterval) {
                console.log(
                  this.TAG,
                  "Recorder: clearing interval",
                  this.liveRecordingInterval
                );
                clearInterval(this.liveRecordingInterval);
                this.liveRecordingInterval = undefined;
                if (this.liveMediaRecorder?.state === "recording") {
                  console.log(
                    this.TAG,
                    "Recorder: Stopping recording (liveMediaRecorder)"
                  );
                  this.liveMediaRecorder.stop();
                }
              }
            }
            this.detectedSilence = true;
          } else {
            this.detectedSilence = false;
          }
        })
        .catch((error) =>
          console.error(
            this.TAG,
            "failed to convert to WAV with error: ",
            error
          )
        );
    }
  }

  /**
   * This is called by the user when activating AudioBot. This requires the incoming audio stream
   * (what we play in the speakers).
   * @returns true if we are able to start the MediaRecorder.
   */
  public startLiveRecording(): boolean {
    const lIncomingStream = this.incomingStream;
    try {
      if (lIncomingStream != null) {
        if (!this.liveMediaRecorder) {
          this.detectedSilence = false;
          this.liveAudioChunks = [];
          this.liveMediaRecorder = new MediaRecorder(lIncomingStream);
          this.liveMediaRecorder.ondataavailable =
            this.onLiveRecorderDataAvailable.bind(this);
        }
        if (this.liveMediaRecorder.state === "inactive") {
          console.log(this.TAG, "Recorder: starting live recording");
          this.liveMediaRecorder.start();
          this.audioBotLiveActive = true;

          const interval = setInterval(() => {
            if (
              this.liveMediaRecorder &&
              this.liveMediaRecorder.state === "recording"
            ) {
              this.liveMediaRecorder?.requestData();
            }
          }, 1000);
          this.liveRecordingInterval = interval;
        }
        return true;
      }
      console.error(this.TAG, "Audio Stream not set");
      return false;
    } catch (e) {
      console.error(
        this.TAG,
        "Recorder: error starting media recorder (",
        e,
        ")"
      );
      this.liveMediaRecorder?.stop();
      this.liveMediaRecorder = undefined;
      return false;
    }
  }

  /**
   * This is called by the user when he stops AudioBot. This triggers also triggers a data available callback.
   */
  public stopLiveRecording() {
    if (this.liveRecordingInterval) {
      console.log(
        this.TAG,
        "Recorder: clearing interval",
        this.liveRecordingInterval
      );
      clearInterval(this.liveRecordingInterval);
      this.liveRecordingInterval = undefined;
    }
    console.log(
      this.TAG,
      "Recorder: mediaRecorder state",
      this.liveMediaRecorder
    );
    if (this.liveMediaRecorder?.state === "recording") {
      console.log(this.TAG, "Recorder: stopping live recording");
      this.liveMediaRecorder.stop();
      this.liveMediaRecorder = undefined;
      this.audioBotLiveActive = false;
    }
  }

  /**
   * This is called by the user when activating AudioBot, this accumulates the whole AudioStream then sends the final
   * product to AudioBot server at the end of the recording.
   * @returns true if we are able to start the media recorder.
   */
  public startRecording() {
    const lMicrophoneStream = this.microphoneStream;
    const lIncomingStream = this.incomingStream;
    try {
      console.log(
        this.TAG,
        `Recorder: lMicrophoneStream: ${lMicrophoneStream}, lIncomingStream: ${lIncomingStream}`
      );
      if (lMicrophoneStream != null && lIncomingStream != null) {
        if (!this.incomingMediaRecorder) {
          this.incomingMediaRecorder = new MediaRecorder(lIncomingStream, {
            audioBitsPerSecond: 16000,
            mimeType: "audio/webm;codecs=opus",
          });
          this.incomingMediaRecorder.ondataavailable = (ev) => {
            this.onCallIncomingRecorderDataAvailable(ev);
          };
        }

        if (!this.microphoneMediaRecorder) {
          this.microphoneMediaRecorder = new MediaRecorder(lMicrophoneStream, {
            audioBitsPerSecond: 16000,
            mimeType: "audio/webm;codecs=opus",
          });
          this.microphoneMediaRecorder.ondataavailable = (ev) => {
            this.onCallMicrophoneRecorderDataAvailable(ev);
          };
        }

        if (
          this.incomingMediaRecorder.state === "inactive" &&
          this.microphoneMediaRecorder.state === "inactive"
        ) {
          this.incomingMediaRecorder.start();
          this.microphoneMediaRecorder.start();

          // We can assume we have a new transcript and calling startRecording
          let newTranscript: Transcript = {};
          newTranscript.transcriptLines = undefined;
          newTranscript.elapsedStart = Date.now() - this.callStartTime;
          this.transcripts.push(newTranscript);

          if (this.onVoiceBotStateChange) {
            this.onVoiceBotStateChange(
              this.callId,
              VOICE_BOT_STATE.recordingStart
            );
          }

          return true;
        }
      }
      return false;
    } catch (e) {
      console.error(
        this.TAG,
        "Recorder: error starting media recorder (",
        e,
        ")"
      );
      this.incomingMediaRecorder?.stop();
      this.incomingMediaRecorder = undefined;
      this.microphoneMediaRecorder?.stop();
      this.microphoneMediaRecorder = undefined;
      return false;
    }
  }

  /**
   * This is called by the user when deactivating the AudioBot feature.
   */
  public stopRecording() {
    if (this.incomingMediaRecorder?.state === "recording") {
      console.log(
        this.TAG,
        "Recorder: Stopping recording (incomingMediaRecorder)"
      );
      this.incomingMediaRecorder.stop();
    }
    if (this.microphoneMediaRecorder?.state === "recording") {
      console.log(
        this.TAG,
        "Recorder: Stopping recording (microphoneMediaRecorder)"
      );
      this.microphoneMediaRecorder.stop();
    }

    let lastTranscript: Transcript | undefined = this.transcripts.pop();
    // If no last transcript, we are in a bad state.
    if (lastTranscript) {
      lastTranscript.elapsedEnd = Date.now() - this.callStartTime;
      console.log(
        this.TAG,
        "stopRecording: setting elpasedEnd to:",
        lastTranscript.elapsedEnd
      );
      this.transcripts.push(lastTranscript);
    }

    if (this.onVoiceBotStateChange) {
      this.onVoiceBotStateChange(
        this.callId,
        VOICE_BOT_STATE.recordingCallTranscriptDone
      );
    }
  }

  //// calls interacting with the voice bot

  /**
   * The POST that will transcribe the Audio stream.
   * @param blob The audio stream
   * @param isLiveTranscription If live, we add diarize which enables User identification based on stereo position.
   * @returns the transcribe audio into text.
   */
  private transcribe(blob: Blob, isLiveTranscription: boolean) {
    console.log(this.TAG, "Recorder: transcribing");
    let formData = new FormData();
    formData.append("temperature", "0.0");
    formData.append("temperature_inc", "0.2");
    formData.append("response_format", "json");
    formData.append("file", blob);
    formData.append("language", "en");
    if (!isLiveTranscription) {
      formData.append("diarize", "1");
    }
    return fetch(new URL("/inference", baseVoiceBotUrl), {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + voiceBotAuthorizationToken,
      },
    });
  }

  /**
   * The POST that will summarize the whole call based on the transcription we send.
   * @param transcriptLines All the lines that were previously transcribed.
   * @returns the summary in text.
   */
  private async getCallSummary(transcriptLines: string) {
    console.log(this.TAG, "Recorder: transcribing");
    return fetch(new URL("/question", baseVoiceBotUrl), {
      method: "POST",
      body: JSON.stringify({
        server: "llama3",
        text: transcriptLines,
        quest: "what is this conversation about?",
      }),
      headers: {
        Authorization: "Bearer " + voiceBotAuthorizationToken,
      },
    });
  }
}
