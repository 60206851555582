const Timer = ({
  time,
  ...props
}: { time: Date } & React.ComponentProps<"p">) => {
  // weird date hours when the date is 0
  if (time.getTime() === 0) {
    time.setHours(0);
  }

  const hours = time.getHours();
  return (
    <span
      css={{
        width: "3em",
      }}
      {...props}
    >
      {!!hours && `${hours}:`}
      {String(time.getMinutes()).padStart(2, "0")}:
      {String(time.getSeconds()).padStart(2, "0")}
    </span>
  );
};

export default Timer;
