import { getDefaultStore } from "jotai";
import { convertToHex8 } from "../colors";
import { atoms } from "../helpers/atoms";
import {
  EndSketchNotificationContent,
  SketchBackgroundNotificationContent,
  SketchDrawingNotificationContent,
  SketchImageNotificationContent,
  SketchInvitationNotificationContent,
  SketchUndoNotificationContent,
} from "../helpers/notificationChannel";
import { sendDelete, sendMessage } from "../messaging";

const defaultStore = getDefaultStore();

export const sendSketch = async (phoneNumber: string, sketch: any) => {
  await send(phoneNumber, JSON.stringify(sketch));
};

export const sendSketchInvite = async (phoneNumber: string, id: number) => {
  await send(phoneNumber, `{"version" : {"id": ${id}}}`);
};

export const endSketch = async (phoneNumber: string) => {
  await send(phoneNumber, '{"close" : ""}');
};

const send = async (phoneNumber: string, payload: string) => {
  await sendMessage({
    to: phoneNumber,
    chatMessage: {
      chatMessage: {
        text: payload,
        contentType: "application/vnd.gsma.sharedsketch+xml",
        reportRequest: [],
      },
    },
  });
};

export const sendSketchBye = async (phoneNumber: string) => {
  await sendDelete("sketch", phoneNumber);
};

export const acceptSketchInvitation = async (
  phoneNumber: string,
  id: number
) => {
  // Same payload as sending the invitation
  await sendSketchInvite(phoneNumber, id);
};

export const rejectSketchInvitation = async (phoneNumber: string) => {
  // Same payload as ending the invitation
  await endSketch(phoneNumber);
  await sendSketchBye(phoneNumber);
};

export function handleSketchInvitation(
  sketchInvitationNotification: SketchInvitationNotificationContent
) {
  defaultStore.set(atoms.sketch.incomingSketchInfos, {
    number: sketchInvitationNotification.sender,
    startFullScreen: true,
    ...sketchInvitationNotification.sketch.version,
  });
}

export function handleEndSketch(
  endSketchNotification: EndSketchNotificationContent
) {
  const code = endSketchNotification.sketch.close;
  defaultStore.set(atoms.sketch.incomingSketchEndedInfos, {
    number: endSketchNotification.sender,
    id: code == "" ? 0 : code,
  });
}

export function handleSketchDrawing(
  sketchDrawingNotification: SketchDrawingNotificationContent
) {
  sketchDrawingNotification.sketch.drawing.color = convertColorForSketch(
    sketchDrawingNotification.sketch.drawing.color,
    false
  );
  defaultStore.set(
    atoms.sketch.incomingSketchDrawing,
    sketchDrawingNotification.sketch
  );
}

export function handleSketchUndo(
  sketchUndoNotification: SketchUndoNotificationContent
) {
  defaultStore.set(
    atoms.sketch.incomingSketchUndo,
    sketchUndoNotification.sketch
  );
}

export function handleSketchBackground(
  sketchBackgroundNotification: SketchBackgroundNotificationContent
) {
  sketchBackgroundNotification.sketch.background_color.color =
    convertColorForSketch(
      sketchBackgroundNotification.sketch.background_color.color,
      false
    );

  defaultStore.set(
    atoms.sketch.incomingSketchBackground,
    sketchBackgroundNotification.sketch
  );
}

export function handleSketchImage(
  sketchImageNotification: SketchImageNotificationContent
) {
  defaultStore.set(
    atoms.sketch.incomingSketchImage,
    sketchImageNotification.sketch
  );
}

// Sketch server color format is hex8 special format with opacity first instead of end, without the #, this method converts it for sending / receiving
export const convertColorForSketch = (color: string, outgoing: boolean) => {
  // Outgoing, we put the alpha at the beginning and remove the trailing #
  if (outgoing) {
    const hex8 = convertToHex8(color);
    return `${hex8.substring(7)}${color.substring(1, 7)}`;
  }
  // Incoming, we put the alpha t the end with trailing #
  else {
    return `#${color.substring(2)}${color.substring(0, 2)}`;
  }
};
