import {
  CapabilityServerStatus,
  ServiceCapabilityArray,
} from "@/types/capabilities";
import { getDefaultStore, useAtomValue } from "jotai";
import {
  CountryCode,
  PhoneNumber,
  formatNumber,
  parsePhoneNumber,
} from "libphonenumber-js";
import { useEffect } from "react";
import { isValidPhoneNumber } from "..";
import { atoms } from "../helpers/atoms";
import { isChatbot } from "../helpers/chatbots";
import { getUserLocationCountry } from "../helpers/localstorage";
import { checkPhoneNumberCaps } from "../helpers/loginAndCaps/capabilities";
import {
  cleanPhoneNumber,
  isPhoneNumberAGroupContributionId,
  isSamePhoneNumber,
} from "../messaging/conversation/conversationUtils/phoneNumberUtils";

const LOG_PREFIX = "useCapabilities: ";

/**
 * This custom hook will be triggered anytime there is a capability change from a fetch caps request.
 * It will then call [callback] only if [condition] is met, with the [capabilityType] found for [phoneNumber].
 */
export function useCapabilitiesOnCacheUpdate({
  condition,
  phoneNumber,
  capabilityType,
  callback,
}: {
  condition: boolean;
  phoneNumber?: string;
  capabilityType: CapabilityType;
  callback: (hasCapability: boolean) => void;
}) {
  const capabilities = useAtomValue(atoms.capabilities.capabilities);

  useEffect(() => {
    if (capabilities && condition && phoneNumber && !isChatbot(phoneNumber)) {
      const caps = getCapabilities(phoneNumber).capabilities;

      if (caps) {
        console.log(
          LOG_PREFIX,
          useCapabilitiesOnCacheUpdate.name,
          ": cache caps for ",
          phoneNumber,
          ": ",
          caps
        );
        const capabilityId = toCapabilityId(capabilityType);
        callback(caps.some((cap) => cap.capabilityId === capabilityId));
      }
    }
  }, [capabilities]);
}

/**
 * This custom hook will be triggered once when the component mounts.
 * If [condition] is met, it will:
 * - either call [callback] with the [capabilityType] found for [phoneNumber] if anything on the local cache.
 * - or trigger a fetch request to the network and:
 * -- call [callback] with true if capabilities not known yet on the server
 * -- or call [callback] with the [capabilityType] found for [phoneNumber] if server knows them
 *
 * Note that this may trigger [useCapabilitiesOnCacheUpdate] in case fetch request is sent
 */
export function useCapabilitiesFromServerOnInit({
  condition,
  phoneNumber,
  capabilityType,
  callback,
}: {
  condition: boolean;
  phoneNumber?: string;
  capabilityType: CapabilityType;
  callback: (hasCapability: boolean) => void;
}) {
  useEffect(() => {
    if (condition && phoneNumber && !isChatbot(phoneNumber)) {
      const caps = getCapabilities(phoneNumber).capabilities;
      const capabilityId = toCapabilityId(capabilityType);

      if (caps) {
        console.log(
          LOG_PREFIX,
          useCapabilitiesFromServerOnInit.name,
          ": ",
          phoneNumber,
          " has capabilities in local cache ",
          caps
        );
        callback(caps.some((cap) => cap.capabilityId === capabilityId));
      } else {
        console.log(
          LOG_PREFIX,
          useCapabilitiesFromServerOnInit.name,
          ": checking caps for ",
          phoneNumber
        );

        checkPhoneNumberCaps(phoneNumber).then((res) => {
          console.log(
            LOG_PREFIX,
            useCapabilitiesFromServerOnInit.name,
            ": server caps for ",
            phoneNumber,
            ": ",
            res
          );

          callback(
            res.status === CapabilityServerStatus.UNKNOWN
              ? true
              : !!res.caps?.some((cap) => cap.capabilityId === capabilityId)
          );
        });
      }
    }
  }, []);
}

export type CapabilitiesOnCache = {
  capabilityExists: boolean;
  hasCapability: boolean;
  capabilities?: ServiceCapabilityArray;
  verseStatus: VerseStatus;
};

export enum VerseStatus {
  INSTALLED,
  NOT_INSTALLED,
  UNKNOWN,
}

export const checkCapabilityOnLocalCache = (
  phoneNumber: string,
  capabilityType: CapabilityType
): CapabilitiesOnCache => {
  if (isChatbot(phoneNumber)) {
    return {
      capabilityExists: true,
      hasCapability: true,
      verseStatus: VerseStatus.INSTALLED,
    };
  }

  const caps = getCapabilities(phoneNumber).capabilities;

  if (caps) {
    const capabilityId = toCapabilityId(capabilityType);

    return {
      capabilityExists: true,
      hasCapability: caps.some((cap) => cap.capabilityId === capabilityId),
      capabilities: caps,
      verseStatus:
        caps?.length > 0 ? VerseStatus.INSTALLED : VerseStatus.NOT_INSTALLED,
    };
  }

  return {
    capabilityExists: false,
    hasCapability: false,
    verseStatus: VerseStatus.UNKNOWN,
  };
};

export const checkCapabilityOnServer = async (
  phoneNumber: string,
  capabilityType: CapabilityType
) => {
  if (isChatbot(phoneNumber)) {
    return {
      capabilityExists: true,
      hasCapability: true,
    };
  }

  const res = await checkPhoneNumberCaps(phoneNumber);

  const capabilityId = toCapabilityId(capabilityType);

  if (res.status === CapabilityServerStatus.FROM_CACHE) {
    return {
      capabilityExists: true,
      hasCapability: !!res.caps?.some(
        (cap) => cap.capabilityId === capabilityId
      ),
    };
  }

  return {
    capabilityExists: false,
    hasCapability: false,
  };
};

const toCapabilityId = (capabilityType: CapabilityType) => {
  switch (capabilityType) {
    case CapabilityType.VOICE:
      return "IPVoiceCall";
    case CapabilityType.VIDEO:
      return "IPVideoCall";
    case CapabilityType.MESSAGING:
      return "Chat";
  }
};

const getCapabilities = (phoneNumber?: string) => {
  phoneNumber = _formatPhoneNumber(phoneNumber);

  if (
    isChatbot(phoneNumber) ||
    isPhoneNumberAGroupContributionId(phoneNumber)
  ) {
    return { phoneNumber, capabilities: [] };
  }

  const capabilitiesPerNumber = getDefaultStore().get(
    atoms.capabilities.capabilities
  );

  let capabilities = capabilitiesPerNumber[phoneNumber];

  // Fallback, check if caps exist for a number with + (non + numbers can never have caps)
  if (!phoneNumber.startsWith("+") && isValidPhoneNumber(phoneNumber)) {
    const key = Object.keys(capabilitiesPerNumber).find(
      (key) => key.startsWith("+") && isSamePhoneNumber(key, phoneNumber)
    );
    if (key) {
      phoneNumber = key;
      capabilities = capabilitiesPerNumber[key];
    }
  }

  return { phoneNumber, capabilities };
};

function _formatPhoneNumber(
  phoneNumber?: string,
  format: "E164" | "E123" = "E164"
) {
  if (
    !phoneNumber ||
    isPhoneNumberAGroupContributionId(phoneNumber) ||
    isChatbot(phoneNumber)
  ) {
    return phoneNumber || "";
  }

  let cleanedNumber = cleanPhoneNumber(phoneNumber);

  if (cleanedNumber.startsWith("00")) {
    cleanedNumber = "+" + cleanedNumber.slice(2);
  }

  // Return international format directly
  if (cleanedNumber.startsWith("+")) {
    cleanedNumber =
      formatNumber(cleanedNumber, "INTERNATIONAL") || cleanedNumber;
    return format === "E164" ? cleanPhoneNumber(cleanedNumber) : cleanedNumber;
  }

  const country = getUserLocationCountry();

  // Try to use local country to build the number
  let parsedNumber: undefined | PhoneNumber;
  try {
    parsedNumber = parsePhoneNumber(
      cleanedNumber,
      country.country_iso_code as CountryCode
    ) as PhoneNumber;
  } catch (e) {}

  // If failed, revert back to original number
  if (!parsedNumber || !parsedNumber.isValid()) {
    return phoneNumber;
  }

  return format === "E164"
    ? cleanPhoneNumber(parsedNumber.number)
    : // Always return national since the number was provided without the international code
      formatNumber(parsedNumber.number.toString(), "NATIONAL");
}

export enum CapabilityType {
  VOICE,
  VIDEO,
  MESSAGING,
}
