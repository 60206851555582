import { formatCallIdWithoutPrefixes } from "../calls/callUtils";
import { addPayloadPartToNmsObject } from "../messaging";
import NmsMessage from "../messaging/NmsMessage";

type TranscriptsToSend = {
  callHistoryNmsObject?: NmsMessage | undefined;
  voiceBotCallTranscripts?: any | undefined;
};

const TAG = "[TranscriptSender]";

export default class TranscriptSender {
  private transcriptSenders: Map<string, TranscriptsToSend>;

  constructor() {
    this.transcriptSenders = new Map();
  }

  private getTranscriptById(callId: string) {
    // Since NMS removes the un-pns- prefix, we're better off removing it everytime.
    const transcriptSender = this.transcriptSenders.get(
      formatCallIdWithoutPrefixes(callId)
    );
    if (transcriptSender) {
      return transcriptSender;
    }
    return {};
  }

  private removeTranscriptByCallId(callId: string) {
    this.transcriptSenders.delete(formatCallIdWithoutPrefixes(callId));
  }

  public addCallHistoryNmsObject(nmsObject: NmsMessage) {
    const callId = nmsObject["imdn.Message-ID"];
    console.log(
      TAG,
      "addCallHistoryNmsObject with CallID=",
      formatCallIdWithoutPrefixes(callId)
    );
    const transcriptSender = this.getTranscriptById(callId);
    console.log(
      TAG,
      "addCallHistoryNmsObject Found transcriptSender ->",
      transcriptSender
    );
    transcriptSender.callHistoryNmsObject = nmsObject;
    this.transcriptSenders.set(
      formatCallIdWithoutPrefixes(callId),
      transcriptSender
    );
    this.checkAndUploadCallTranscriptToNms(callId);
  }

  public addVoiceBotCallTranscript(callId: string, transcript: any) {
    console.log(
      TAG,
      "addVoiceBotCallTranscript with CallID=",
      formatCallIdWithoutPrefixes(callId)
    );
    const transcriptSender = this.getTranscriptById(callId);
    console.log(
      TAG,
      "addVoiceBotCallTranscript Found transcriptSender ->",
      transcriptSender
    );
    transcriptSender.voiceBotCallTranscripts = transcript;
    this.transcriptSenders.set(
      formatCallIdWithoutPrefixes(callId),
      transcriptSender
    );
    this.checkAndUploadCallTranscriptToNms(callId);
  }

  private checkAndUploadCallTranscriptToNms(callId: string) {
    const transcriptSender = this.getTranscriptById(callId);
    console.log(TAG, "transcriptSender=", JSON.stringify(transcriptSender));
    if (
      transcriptSender.callHistoryNmsObject &&
      transcriptSender.voiceBotCallTranscripts
    ) {
      // This means we are ready to upload to NMS, we need the final transcript done, we need the call history
      // NMS object & the call to be done.
      console.log(
        TAG,
        ": uploadCallTranscriptToNms: objectId:",
        transcriptSender.callHistoryNmsObject.ObjectId
      );
      addPayloadPartToNmsObject(
        transcriptSender.callHistoryNmsObject.ObjectId,
        transcriptSender.voiceBotCallTranscripts
      );
      this.removeTranscriptByCallId(callId);
    }
  }
}
