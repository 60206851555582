import { CapabilityNotification } from "@/utils/helpers/notificationChannel";

export type ServiceCapabilityArray = Array<{
  capabilityId: CapabilityId;
  status: "Enabled";
}>;

type CapabilityId =
  | "StandaloneMessaging"
  | "Chat"
  | "IPVideoCall"
  | "RCSIPVideoCall"
  | "IPVoiceCall"
  | "Chatbot"
  | "FileTransferViaHTTP"
  | "FileTransfer"
  | "GeolocationPush";

export type CapabilitiesPerNumber = {
  [phoneNumber: string]: ServiceCapabilityArray;
};

export type CapabilityFetchResult = {
  status: CapabilityServerStatus;
  caps?: CapabilityNotification;
};

export enum CapabilityServerStatus {
  FROM_CACHE,
  UNKNOWN,
}
