import { useNetworkState } from "@uidotdev/usehooks";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { WeakConnectionAnimation } from "../shared/Loaders/ReconnectingAnimation";
export const BAD_CONNECTION_TOAST_ID = "badConnectionToastId";

function NetworkState({ v }: { v: React.MutableRefObject<any | null> }) {
  const [downlink, setDownlink] = useState(0);
  const timeReceived = useRef<number>(0);
  const [freezeInc, setFreezeInc] = useState(0);
  const freezeCount = useRef<number>(0);
  const bytesReceived = useRef<number>(0);
  const network = useNetworkState();

  useEffect(() => {
    let t: number;
    const doStatUpdate = async () => {
      const pc = v?.current?.getRtc();
      if (pc) statUpdate(await pc.getStats());

      t = +setTimeout(doStatUpdate, 1000);
    };
    t = +setTimeout(doStatUpdate, 1000);

    return () => {
      clearTimeout(t);
    };
  }, []);

  const statUpdate = (stats: RTCStatsReport) => {
    const currentTime = Date.now();

    stats.forEach((report) => {
      if (
        report.kind === "video" &&
        report.type === "inbound-rtp" &&
        currentTime !== timeReceived.current
      ) {
        const bytes = report.bytesReceived;
        if (bytes) {
          setDownlink(
            (bytes - bytesReceived.current) /
              (currentTime - timeReceived.current)
          );
          bytesReceived.current = bytes;
          timeReceived.current = currentTime;
        }
        //console.log(freezeCount, bytesReceived, downlink) // don't useState
        const freeze = report.freezeCount;
        if (freeze) {
          setFreezeInc(freeze - freezeCount.current);
          freezeCount.current = freeze;
        }
      }
    });
  };

  useEffect(() => {
    if (network.downlink || downlink > 0) {
      console.log(network.downlink, "downlink", downlink);
      if (downlink < 100 && bytesReceived.current > 10000 && freezeInc) {
        toast(
          "Poor Internet Connection.\nYou may experience interruptions or lower resolution during playback",
          {
            style: { backgroundColor: "#2E3237", color: "#FFFFFF" },
            icon: <WeakConnectionAnimation />,
            id: BAD_CONNECTION_TOAST_ID,
            duration: Infinity,
          }
        );
      } else {
        toast.dismiss(BAD_CONNECTION_TOAST_ID);
      }
    }
    return () => {
      toast.dismiss(BAD_CONNECTION_TOAST_ID);
    };
  }, [network.online, network.downlink, downlink, bytesReceived, freezeInc]);

  return null;
}

export default NetworkState;
